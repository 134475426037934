import { NavigateFunction } from 'react-router-dom';

import { ChecklistItemDefinition } from '../../api/checklistItemDefinitions';

import { ChecklistItemIdentifier } from './identifiers';

export const getOnAfterCompletedClickHandler = ({
  item,
  navigate,
  ceremonyId,
}: {
  item: ChecklistItemDefinition;
  navigate: NavigateFunction;
  ceremonyId: number | null | undefined;
}) => {
  switch (item.identifier) {
    case ChecklistItemIdentifier.ORDAINED:
      return () => {
        if (
          item.member_checklist_item &&
          item.member_checklist_item.completed
        ) {
          if (ceremonyId) {
            navigate(`/ceremony/${ceremonyId}/ordained`);
          }
        }
      };
    default:
      return;
  }
};
