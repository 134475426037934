import Button from '../../../../components/v2/Button/Button';
import { useCTA } from '../../../../provider/ctaProvider';
import { ModuleStatus } from '../../enum';

const GenerateButton = ({
  size,
  onClick,
  onClickWithStatus,
  fullWidth,
  text,
}: {
  size: '100' | '200' | '400';
  onClick?: () => void;
  onClickWithStatus?: (status: {
    status: string;
    moduleStatus: ModuleStatus;
  }) => void;
  fullWidth?: boolean;
  text?: string;
}) => {
  const { status, moduleStatus } = useCTA();

  const disabled =
    (moduleStatus !== ModuleStatus.completed &&
      moduleStatus !== ModuleStatus.requiredAnswered) ||
    status !== 'saved';

  return (
    <Button
      fullWidth={fullWidth}
      size={size}
      disabled={disabled}
      onClick={
        onClick ||
        (() => onClickWithStatus && onClickWithStatus({ status, moduleStatus })) // Either onClick or onClickWithStatus is required
      }
    >
      <>
        {!text && status !== 'saved' ? 'Saving Answers' : text ? text : 'Generate Section'}
      </>
    </Button>
  );
};

export default GenerateButton;
