import { User } from '../api/auth';

import { capitalizeFirstLetter } from './helper';

export const getFullName = (user?: User) => {
  let result = '';
  if (user && user.first_name) {
    result = user.first_name;
    if (user.last_name) {
      result = user.first_name + ' ' + user.last_name;
    }
  }
  return result;
};

export const getFirstName = (fullName: string | undefined | null) => {
  if (!fullName) return '';

  const nameParts = fullName.split(' ');

  if (nameParts.length > 1) return capitalizeFirstLetter(nameParts[0]);

  return fullName;
};

export const getLastName = (fullName: string | undefined | null) => {
  if (!fullName) return '';

  const nameParts = fullName.split(' ');
  if (nameParts.length > 1) return capitalizeFirstLetter(nameParts[nameParts.length - 1]);

  return '';
};
