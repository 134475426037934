import { FieldProps } from 'formik';

import CustomTextInput from '../../../components/forms/CustomTextInput';

interface CustomInputProps {
  type?: 'text' | 'number' | 'password' | 'email';
  placeholder?: string;
  setChanged: () => void;
  readOnly?: boolean;
  className?: string;
  error?: boolean;
  helperText: string;
  infoText?: string;
  required?: boolean;
}

export const CustomFormikInput = ({
  field, // { name, value, onChange, onBlur }
  form,
  className,
  setChanged,
  error,
  helperText,
  infoText,
  required = false,
  ...props
}: // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
CustomInputProps & FieldProps<string>) => {
  return (
    <CustomTextInput
      className={className}
      value={field.value}
      name={field.name}
      onChange={(ev) => {
        field.onChange(ev);
        if (setChanged) {
          setChanged();
        }
      }}
      onBlur={(ev) => {
        field.onChange(ev);
        if (setChanged) {
          setChanged();
        }
      }}
      placeholder={props.placeholder}
      required={required}
      type={props.type}
      readOnly={props.readOnly}
      infoText={infoText}
      error={error}
      helperText={helperText}
      {...props}
    />
  );
};
