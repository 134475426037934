import classNames from 'classnames';
import {
  ChangeEvent,
  FocusEventHandler,
  InputHTMLAttributes,
  MouseEventHandler,
  useState,
} from 'react';

import './customTextInput.scss';

import { Typography } from '../v2/Typography/Typography';
import InfoIcon from '../v2/InfoIcon/InfoIcon';
import EyeIcon from '../v2/EyeIcon/EyeIcon';

interface CustomTextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  multiline?: boolean;
  rows?: string | number;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  label?: string;
  name?: string;
  helperText?: string;
  error?: boolean;
  readOnly?: boolean;
  customSize?: 200 | 400;
  fullWidth?: boolean;
  infoText?: string;
  eyeIcon?: boolean;
}

const CustomTextInput = ({
  label,
  className,
  disabled,
  type,
  placeholder,
  onClick,
  name,
  readOnly,
  helperText,
  error,
  size = 400,
  fullWidth = false,
  onChange,
  infoText,
  eyeIcon,
  ...props
}: CustomTextInputProps) => {
  const [inputType, setInputType] = useState(type);

  const toggleShowPassword = () => {
    if (inputType == 'text') {
      setInputType('password');
    }
    if (inputType == 'password') {
      setInputType('text');
    }
  };

  type = inputType;

  return (
    <>
      {label && (
        <div className='mb-2'>
          <label>
            <Typography
              variant='functional-low'
              type='body-200'
              className={disabled ? 'inline text-neutral-400' : 'inline'}
            >
              <>{label}</>
            </Typography>
            {props.required && (
              <Typography
                variant='functional-low'
                type='body-200'
                className='ml-1 inline text-copper-primary'
              >
                (Required)
              </Typography>
            )}
          </label>
        </div>
      )}
      <div
        className={`mb-4 ${fullWidth ? 'w-full' : ''} ${
          infoText || eyeIcon ? 'relative' : ''
        }`}
      >
        <input
          {...props}
          className={classNames(
            'custom-text-input',
            `custom-text-input-size-${size}`,
            className,
            {
              'custom-text-input-error': !!error,
              'text-neutral-400': disabled,
            }
          )}
          onClick={onClick}
          disabled={disabled}
          type={type}
          placeholder={placeholder}
          name={name || ''}
          readOnly={readOnly}
          onChange={onChange}
          onWheel={(e) => (e.target as HTMLElement).blur()}
        />
        {error && helperText && (
          <div className='mt-2'>
            <Typography
              className='text-red-primary'
              variant='functional-low'
              type='body-100'
            >
              <>{helperText}</>
            </Typography>
          </div>
        )}
        {infoText ? (
          <InfoIcon info={infoText} className='absolute right-0 top-5' />
        ) : null}

        {eyeIcon ? (
          <EyeIcon
            onClick={() => {
              toggleShowPassword();
            }}
          />
        ) : null}
      </div>
    </>
  );
};

export default CustomTextInput;
