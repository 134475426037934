import moment from 'moment';
import ReactGA from 'react-ga4';

import {
  Ceremony,
  MemberType,
  UpdateCeremonyVenueAndPlace,
  updateCeremonyVenueAndDate,
} from '../../../../api/ceremony';
import chevronDownSvg from '../../../../assets/images/icons/chevron-down.svg';
import chevronUpSvg from '../../../../assets/images/icons/chevron-up.svg';
import { EventSettingsModal } from '../../../../components/EventSettingsModal/EventSettingsModal';
import Loader from '../../../../components/Loader/loader';
import Button from '../../../../components/v2/Button/Button';
import { DeactivatingModal } from '../../../../components/v2/DeactivatingModal/DeactivatingModal';
import { Separator } from '../../../../components/v2/Separator/Separator';
import { Typography } from '../../../../components/v2/Typography/Typography';
import { getCeremonyTitleFromCoupleName } from '../../../../helpers/ceremonyHelper';
import { sendCustomerIoEventHandler } from '../../../../helpers/customerIoHelper';
import {
  getMemberSubTypeTitle,
  getMemberTypeTitle,
} from '../../../../helpers/dropdownHelper';
import { getVenueName } from '../../../../helpers/helper';
import useLoader from '../../../../hooks/useLoader';
import { useDashboard } from '../../../../provider/dashboardProvider';
import { useSnackbar } from '../../../../provider/snackbarProvider';

import './ceremonyDetailsCard.scss';

import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import {
  Divider,
  ListItemIcon,
  Button as MUIButton,
  Menu,
  MenuItem,
} from '@mui/material';

import useUser from '../../../../hooks/useUser';

import { useNavigate, useParams } from 'react-router-dom';

import { useCreateEvent } from '../../../../provider/createEventProvider';

import { Add, Check, ContentCopy } from '@mui/icons-material';

import { Drawer } from '../../../ModulesV2/components/Drawer/Drawer';
import { useWindowSize } from '../../../../hooks/useWindowSize';

export type CeremonyDetailsCardProps = {
  expanded: boolean;
};

export const CeremonyDetailsCard = (props: CeremonyDetailsCardProps) => {
  const { expanded } = props;
  const { isShowing, showLoader, hideLoader } = useLoader();
  const size = useWindowSize();
  const { openSnackBar } = useSnackbar();
  const {
    ceremony,
    couple1,
    couple2,
    currentRole,
    currentUser,
    weddingPlanner,
    eventSettingsModalOpen,
    setEventSettingsModalOpen,
    fetchCeremony,
  } = useDashboard();
  const { setEventRoleModalOpen } = useCreateEvent();
  const { userCeremonies, fetchCeremonies } = useUser();
  const navigate = useNavigate();

  let coupleNamesString = '';
  let coupleNamesStringShort = '';
  if (couple1 && couple2 && couple2.legal_name !== 'TBD TBD') {
    coupleNamesString = `${couple1?.preferred_name} & ${couple2?.preferred_name}`;
    coupleNamesStringShort = `${couple1?.preferred_name[0]}&${couple2?.preferred_name[0]}`;
  } else if (couple1) {
    coupleNamesString = couple1?.preferred_name;
    coupleNamesStringShort = couple1?.preferred_name[0];
  } else if (couple2) {
    coupleNamesString = couple2?.preferred_name;
    coupleNamesStringShort = couple2?.preferred_name[0];
  }
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorElTop, setAnchorElTop] = useState<null | HTMLElement>(null);
  const [anchorElBottom, setAnchorElBottom] = useState<null | HTMLElement>(
    null
  );
  const openTop = Boolean(anchorElTop);
  const openBottom = Boolean(anchorElBottom);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElTop(event.currentTarget);
  };

  useEffect(() => {
    //TODO - this should be refactored, so that it's not a hook but part of a context
    void fetchCeremonies();
  }, [ceremony]);

  const handleClose = () => {
    setDrawerOpen(false);
    setAnchorElTop(null);
    setAnchorElBottom(null);
  };

  const handleSaveChanges = async (values: UpdateCeremonyVenueAndPlace) => {
    showLoader();

    try {
      void sendCustomerIoEventHandler('Event Settings Updated', {
        venue: `${values.venue}, ${values.city}, ${values.state} ${values.wedding_place}`,
        ceremony_date: values.weddingDate
          ? Math.floor(new Date(values.weddingDate).getTime() / 1000)
          : null,
      });

      const response = await updateCeremonyVenueAndDate({ ...values });
      if (response.success) {
        setEventSettingsModalOpen(false);
        openSnackBar(response.message);
        void fetchCeremony(values.id.toString());
      } else {
        openSnackBar(response.message, 'error');
      }
    } catch (err) {
      hideLoader();
      if (err instanceof Error) {
        openSnackBar(err.message, 'error');
      } else {
        openSnackBar('Cannot update Place / Date', 'error');
      }
    }

    hideLoader();
  };

  const getMenu = (anchorEl: null | HTMLElement, open: boolean) => {
    return (
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: '#EFEDE7',
            boxShadow:
              '0px 1px 8px rgba(53, 71, 64, 0.16), 0px 2px 3px rgba(53, 71, 64, 0.08)',
            borderRadius: 0,
            borderWidth: '1px',
            borderColor: '#9AA3A0',
          },
        }}
      >
        <Typography
          className='px-[16px] py-[6px]'
          variant='functional-high'
          type='body-100'
        >
          SWITCH WEDDINGS
        </Typography>
        {userCeremonies &&
          userCeremonies.length > 0 &&
          userCeremonies.map((uc) => (
            <MenuItem
              key={uc.id}
              onClick={() => {
                handleClose();
                if (ceremony?.id !== uc.id) {
                  navigate(`/ceremony/${uc.id}`);
                }
              }}
            >
              <Typography
                className='mr-4'
                variant='functional-low'
                type='body-400'
              >
                {getCeremonyTitleFromCoupleName(uc.coupleName)}
              </Typography>

              {ceremony?.id === uc.id && (
                <ListItemIcon>
                  <Check />
                </ListItemIcon>
              )}
            </MenuItem>
          ))}
        <Divider />
        <MenuItem
          onClick={() => {
            handleClose();
            ReactGA.event({
              category: 'Ceremony',
              action: 'Create',
              label: 'Start creation',
            });

            setEventRoleModalOpen(true);
          }}
        >
          <ListItemIcon>
            <Add />
          </ListItemIcon>
          Create New Event
        </MenuItem>
      </Menu>
    );
  };

  return (
    <>
      <div
        className={classNames('CeremonyDetailsCard-root', {
          collapsed: !expanded,
        })}
      >
        {expanded ? (
          <>
            <div className='CeremonyDetailsCard-header'>
              <div className='max-w-[130px]'>
                <Typography type='body-400' className='text-cashmere-800'>
                  {getCeremonyTitleFromCoupleName(coupleNamesString).replace(
                    'Wedding',
                    ''
                  )}
                </Typography>
                <Typography type='body-400' className=' text-cashmere-800'>
                  Wedding
                </Typography>
              </div>

              <img
                className='cursor-pointer'
                onClick={(ev) => {
                  if (size.isMobile) {
                    setDrawerOpen(true);
                  } else {
                    handleClick(ev);
                  }
                }}
                src={openTop ? chevronUpSvg : chevronDownSvg}
              />
            </div>
            <div className='CeremonyDetailsCard-info'>
              <div className='CeremonyDetailsCard-info-item'>
                <Typography
                  className='text-cashmere-800'
                  variant='functional-high'
                  type='body-100'
                >
                  Your Role
                </Typography>
                <Typography
                  className='text-cashmere-800'
                  variant='functional-low'
                  type='body-100'
                >
                  {currentUser?.member_type !== MemberType.guests && currentRole
                    ? getMemberTypeTitle(currentRole)
                    : getMemberSubTypeTitle(currentUser?.member_sub_type) ||
                      'Guest'}
                </Typography>
              </div>
              <Separator
                variant='horizontal'
                className='my-2 border-cashmere-800'
              />
              <div className='CeremonyDetailsCard-info-item'>
                <Typography
                  className='text-cashmere-800'
                  variant='functional-high'
                  type='body-100'
                >
                  Wedding Date
                </Typography>
                <Typography
                  className='text-cashmere-800'
                  variant='functional-low'
                  type='body-100'
                >
                  {ceremony?.date
                    ? moment.utc(ceremony?.date).format('MMM Do')
                    : '-'}
                </Typography>
              </div>

              <Separator
                variant='horizontal'
                className='my-2 border-cashmere-800'
              />
              <div className='CeremonyDetailsCard-info-item'>
                <Typography
                  className='text-cashmere-800'
                  variant='functional-high'
                  type='body-100'
                >
                  Venue
                </Typography>
                <Typography
                  className='text-cashmere-800'
                  variant='functional-low'
                  type='body-100'
                >
                  {getVenueName(
                    ceremony?.venue ? ceremony.venue : '-',
                    ceremony?.city,
                    ceremony?.state,
                    ceremony?.wedding_place
                  )}
                </Typography>
              </div>
            </div>
            {currentRole !== 'guest' && (
              <Button
                className='mt-4'
                size='100'
                onClick={() => setEventSettingsModalOpen(true)}
                variant='text'
              >
                Event Settings
              </Button>
            )}
            {getMenu(anchorElTop, openTop)}
            <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)}>
              <div className='p-8'>
                <Typography
                  className='px-[16px] py-[6px]'
                  variant='functional-high'
                  type='body-100'
                >
                  SWITCH WEDDINGS
                </Typography>
                {userCeremonies &&
                  userCeremonies.length > 0 &&
                  userCeremonies.map((uc) => (
                    <MenuItem
                      key={uc.id}
                      onClick={() => {
                        handleClose();
                        if (ceremony?.id !== uc.id) {
                          navigate(`/ceremony/${uc.id}`);
                        }
                      }}
                    >
                      <Typography
                        className='mr-4'
                        variant='functional-low'
                        type='body-400'
                      >
                        {getCeremonyTitleFromCoupleName(uc.coupleName)}
                      </Typography>

                      {ceremony?.id === uc.id && (
                        <ListItemIcon>
                          <Check />
                        </ListItemIcon>
                      )}
                    </MenuItem>
                  ))}
                <Divider />
                <MenuItem
                  onClick={() => {
                    handleClose();
                    ReactGA.event({
                      category: 'Ceremony',
                      action: 'Create',
                      label: 'Start creation',
                    });

                    setEventRoleModalOpen(true);
                  }}
                >
                  <ListItemIcon>
                    <Add />
                  </ListItemIcon>
                  Create New Event
                </MenuItem>

                <Button
                  className='w-full mt-6'
                  variant='secondary'
                  onClick={() => setDrawerOpen(false)}
                >
                  Close
                </Button>
              </div>
            </Drawer>
          </>
        ) : (
          <Typography type='body-400' className='text-cashmere-800'>
            {coupleNamesStringShort}
          </Typography>
        )}
      </div>

      <div className='text-center'>
        {userCeremonies && userCeremonies.length > 1 ? (
          <>
            <Button
              className='mt-4'
              size='100'
              eventClick={(event) => {
                if (size.isMobile) {
                  setDrawerOpen(true);
                } else {
                  handleClick(event);
                }
              }}
              variant='text'
            >
              <p>Switch Events ({userCeremonies.length})</p>
            </Button>
            {getMenu(anchorElBottom, openBottom)}
          </>
        ) : null}
      </div>

      <Loader isShowing={isShowing} hide={hideLoader} allowHide={false} />
      {currentUser && ceremony && (
        <EventSettingsModal
          isOpen={eventSettingsModalOpen}
          onClose={() => setEventSettingsModalOpen(false)}
          ceremony={ceremony}
          onSaveChanges={handleSaveChanges}
          currentUser={currentUser}
          partner={currentUser.mem_id === couple1?.mem_id ? couple2 : couple1}
          weddingPlanner={weddingPlanner}
          // onAddCeremonyMember={onAddCeremonyMember}
        />
      )}
      {currentUser && currentUser.id && ceremony && (
        <DeactivatingModal
          userId={currentUser?.id.toString()}
          ceremony={ceremony}
        />
      )}
    </>
  );
};
