import { _request } from './request';



export type AddonsResponse = {
  success: boolean;
  message: string;
  data: Addon[];
};

export type AddonResponse = {
  success: boolean;
  message: string;
  data: Addon;
};

export type Addon = {
    id: number;
    name: string;
    description: string;
    price: number;
    oldPrice: number;
    identifier: AddonIdentifiersEnum;
    status: string;
    order: number;
    createdAt: string;
    updatedAt: string;
};

export type AddonIdentifiersEnum = 'community-reflections';

export const getAddons = async () => {
  const responseBody = await _request({
    url: `addons`,
    method: 'GET',
  });
  return responseBody as AddonsResponse;
};
