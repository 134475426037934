import React, { useEffect, Suspense, lazy } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { getMe, MeResponse } from './api/auth';
import Loader from './components/Loader/loader';
import { PageWrapper } from './components/PageWrapper/PageWrapper';
import { DraftingCeremonyModal } from './pages/AiCeremonyDesigner/DraftingCeremonyModal';
import BabyLander from './pages/BabyLander/BabyLander';
import { CeremonyChecklistDashboard } from './pages/Ceremony/CeremonyChecklist/CeremonyChecklistDashboardNew';
import CeremonyDesignerLander from './pages/Ceremony/CeremonyDesigner/CeremonyDesignerLander';
import GuestChecklist from './pages/Ceremony/GuestChecklist/GuestChecklist';
import GuestDashboard from './pages/Ceremony/GuestDashboard/GuestDashboard';
import AddCeremonyPage from './pages/Ceremony/PlannerDashboard/AddCeremonyPage';
import { ClientsList } from './pages/Ceremony/PlannerDashboard/ClientsList/ClientsList';
import { ClientsResources } from './pages/Ceremony/PlannerDashboard/ClientsResources/ClientsResources';
import { PlannerOnboarding } from './pages/Ceremony/PlannerDashboard/Onboarding/OnboardingPage';
import { PlannerDashboard } from './pages/Ceremony/PlannerDashboard/PlannerDashboard';
import { RedirectHandler } from './pages/Ceremony/RedirectHandler/RedirectHandler';
import { ToastBuilderDashboard } from './pages/Ceremony/ToastBuilderDashboard/ToastBuilderDashboard';
import { VowBuilderDashboard } from './pages/Ceremony/VowBuilderDashboard/VowBuilderDashboard';
import GiftcardSend from './pages/Giftcard/GiftcardSend';
import { LogisticsModule } from './pages/ModulesV2/Logistics/LogisticsModule';
import { LoveStoryModule } from './pages/ModulesV2/LoveStory/LoveStoryModule';
import { PronouncementModule } from './pages/ModulesV2/Pronouncement/PronouncementModule';
import { ReadingsModule } from './pages/ModulesV2/Readings/ReadingsModule';
import { RingExchangeModule } from './pages/ModulesV2/RingExchange/RingExchangeModule';
import { ToastModule } from './pages/ModulesV2/Toast/ToastModule';
import { VowsModule } from './pages/ModulesV2/Vows/VowsModule';
import { VowsCeremonyModule } from './pages/ModulesV2/VowsCeremony/VowsCeremonyModule';
import ReflectionsLander from './pages/ReflectionsLander/ReflectionsLander';
import SpeechwritingLander from './pages/SpeechwritingLander/SpeechwritingLander';
import ThankYouNotesLander from './pages/ThankYouNotesLander/ThankYouNotesLander';
import { DraftingToastModal } from './pages/Toast/DraftingToastModal';
import ToastBuilderNewest from './pages/Toast/ToastBuilderNewest';
import { DraftingVowsModal } from './pages/Vows/DraftingVowsModal';
import Waitlist from './pages/Waitlist';
import { DraftingWelcomeToastModal } from './pages/WelcomeToast/DraftingWelcomeToastModal';
import { WelcomeToastModule } from './pages/WelcomeToast/WelcomeToastModule';
import PayConfirmation from './pages/paywall/PayConfirmation';
import PayDetails from './pages/paywall/PayDetails';
import PayPlans from './pages/paywall/PayPlans';
import PayStatus from './pages/paywall/PayStatus';
import PaySummary from './pages/paywall/PaySummary';
import ProcessPay from './pages/paywall/ProcessPay';
import SubscriptionConfirmation from './pages/paywall/SubscriptionConfirmation';
import PaywallMerchandising from './pages/paywallMerchandising/PaywallMerchandising';
import PaywallToastBuilder from './pages/paywallToastBuilder/PaywallToastBuilder';
import { WPPayPlans } from './pages/paywallWP/WPPayPlans';
import { NonAuth, RequireAuth, useAuth } from './provider/authProvider';
import { useActiveCampaign } from './provider/campaignProvider';
import { ReadingsProvider } from './provider/readingsProvider';

const Publicspeaking = lazy(() => import('./pages/PublicSpeaking'));
const Referral = lazy(() => import('./pages/Referral'));
const Toast = lazy(() => import('./pages/Toast/index'));
// const AiToastBuilder = lazy(
//   () => import('./pages/AiToastBuilder/AiToastBuilder')
// );
const CeremonyBuilder = lazy(
  () => import('./pages/AiCeremonyDesigner/CeremonyBuilderNewest')
);
const Output = lazy(() => import('./pages/Output/Output'));
const PreviewScript = lazy(() => import('./pages/Export/PreviewScript'));
const PreviewVows = lazy(() => import('./pages/Export/PreviewVows'));
const PreviewToast = lazy(() => import('./pages/Export/PreviewToast'));
const VowBuilder = lazy(() => import('./pages/Vows/VowBuilderNewest'));
const WelcomeToastBuilder = lazy(
  () => import('./pages/WelcomeToast/WelcomeToastBuilder')
);
const Export = lazy(() => import('./pages/Export/Export'));
const ExportToast = lazy(() => import('./pages/Export/ExportToast'));
const ExportToastAi = lazy(() => import('./pages/Export/ExportToastAI'));
const ExportVows = lazy(() => import('./pages/Export/ExportVows'));
const ExportVowsAI = lazy(() => import('./pages/Export/ExportVowsAI'));

const ResetPassword = lazy(
  () => import('./pages/ResetPassword/ResetPasswordNew')
);
const ResetPasswordSuccess = lazy(
  () => import('./pages/ResetPassword/ResetPasswordSuccess')
);
const ResetPasswordError = lazy(
  () => import('./pages/ResetPassword/ResetPasswordError')
);
const NotFound = lazy(() => import('./pages/NotFound'));
const ForgotPassword = lazy(
  () => import('./pages/ForgotPassword/ForgotPasswordNew')
);
const SurveyInvite = lazy(() => import('./pages/SurveyInvite'));
const SignUp = lazy(() => import('./pages/.Signup'));
const VowsSignup = lazy(() => import('./pages/Signup/VowsSignup'));
const SignUpWelcome = lazy(() => import('./pages/Signup/Welcome'));
const SignUpStep1 = lazy(() => import('./pages/Signup/Steps/Step1'));
const SignUpStep2 = lazy(() => import('./pages/Signup/Steps/Couple/Step2'));
const SignUpStep3 = lazy(() => import('./pages/Signup/Steps/Couple/Step3'));
const SignUpStep4 = lazy(() => import('./pages/Signup/Steps/Couple/Step4'));
const SignUpStep5 = lazy(() => import('./pages/Signup/Steps/Couple/Step5'));
const SignUpStep6 = lazy(() => import('./pages/Signup/Steps/Couple/Step6'));
const SignupStep3Officiant = lazy(
  () => import('./pages/Signup/Steps/Officiant/Step3')
);
const SignupStep3Guest = lazy(() => import('./pages/Signup/Steps/Guest/Step3'));
const SignupStep4wedding_planner = lazy(
  () => import('./pages/Signup/Steps/WeddingPlanner/Step4')
);
const SignupCredentials = lazy(() => import('./pages/Signup/Signup'));
const SignupCredentialsManual = lazy(
  () => import('./pages/Signup/Signup/ManualSignup')
);
const SignIn = lazy(() => import('./pages/Signin'));
const ReviewLink = lazy(() => import('./pages/ReviewLink'));
const VerifyEmail = lazy(() => import('./pages/VerifyEmail'));
const ChangeEmail = lazy(() => import('./pages/ChangeEmail'));
const EventsDashboard = lazy(
  () => import('./pages/EventsDashboard/EventsDashboard')
);
const Modules = lazy(() => import('./pages/Modules/Modules'));
const AddCeremony = lazy(
  () => import('./pages/Ceremony/PlannerDashboard/AddCeremonyPage')
);
const Settings = lazy(() => import('./pages/Settings'));

const Refer = lazy(() => import('./pages/Refer'));
const Ordained = lazy(() => import('./pages/Ordained/OrdainedPage'));

const withSuspense = (
  Component: React.ComponentType,
  props?: Record<string, unknown>
) => (
  <Suspense fallback={<Loader isShowing={true} allowHide={false} />}>
    <Component {...props} />
  </Suspense>
);

const AppRoutes = () => {
  const auth = useAuth();
  const { fetchCampaign } = useActiveCampaign();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const location = useLocation();

  useEffect(() => {
    if (auth.token) {
      void getProfile();
      void fetchCampaign();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.token]);

  const getProfile = async () => {
    const meResponse: MeResponse = await getMe();
    if (meResponse.success) {
      auth.setUserData(meResponse.user);
    }
  };

  return (
    <React.Fragment>
      <DraftingVowsModal />
      <DraftingToastModal />
      <DraftingCeremonyModal />
      <DraftingWelcomeToastModal />
      <Routes location={(location?.state as any)?.background || location}>
        <Route path='/' element={<Navigate to='/signin' replace />} />
        <Route
          path='/refer/:referId'
          element={withSuspense(Refer, { referId: 'referId' })}
        />
        <Route
          path='/verify-email/:verifyId'
          element={withSuspense(VerifyEmail)}
        />
        <Route
          path='/change-email/:verifyId'
          element={withSuspense(ChangeEmail)}
        />

        <Route
          path='/signup-credentials/:memberType'
          element={withSuspense(SignupCredentials)}
        />
        <Route
          path='/signup-credentials-manual/:memberType'
          element={withSuspense(SignupCredentialsManual)}
        />

        <Route element={<NonAuth />}>
          <Route path='/signin' element={withSuspense(SignIn)} />
          <Route path='/signup' element={withSuspense(SignUp)} />

          <Route path='/signup-vows' element={withSuspense(VowsSignup)} />
          <Route path='/signup-welcome' element={withSuspense(SignUpWelcome)} />

          {/* Step 1 is dettermines your role (and therefore your sign in path) */}
          <Route path='/signup-step1' element={withSuspense(SignUpStep1)} />

          {/* Step 2 is common for all roles except for couples */}
          <Route
            path='/signup-step2/:memberType'
            element={withSuspense(SignUpStep2)}
          />
          <Route
            path='/signup-step2/wedding_planner'
            element={withSuspense(SignUpStep3)}
          />

          {/* Step 3 is different for every role */}

          <Route
            path='/signup-step3/guest'
            element={withSuspense(SignUpStep3)}
          />
          <Route
            path='/signup-step3/officiant'
            element={withSuspense(SignUpStep3)}
          />

          <Route
            path='/signup-step4/guest'
            element={withSuspense(SignupStep3Guest)}
          />
          <Route
            path='/signup-step4/officiant'
            element={withSuspense(SignupStep3Officiant)}
          />
          {/* This route will be consider the generic, although only used by couple */}
          <Route
            path='/signup-step3/:memberType'
            element={withSuspense(SignUpStep3)}
          />

          {/* Step 4 is different for every role */}
          <Route
            path='/signup-step5/guest'
            element={withSuspense(SignupStep3Officiant)}
          />

          <Route
            path='/signup-step5/officiant'
            element={withSuspense(SignUpStep5)}
          />

          <Route
            path='/signup-step4/:memberType'
            element={withSuspense(SignUpStep4)}
          />

          {/* Step 5 is common for couples and guests. Officiant have another component */}
          <Route
            path='/signup-step6/officiant'
            element={withSuspense(SignUpStep6)}
          />
          <Route
            path='/signup-step6/guest'
            element={withSuspense(SignUpStep5)}
          />
          <Route
            path='/signup-step5/:memberType'
            element={withSuspense(SignUpStep5)}
          />

          {/* Step 6 is common for couples and guests. (which are all roles that make it this far) */}
          <Route
            path='/signup-step6/:memberType'
            element={withSuspense(SignUpStep6)}
          />

          {/* I'm not sure that any role make it to step7 */}
          <Route
            path='/signup-step7/guest'
            element={withSuspense(SignUpStep6)}
          />

          <Route path='/review' element={withSuspense(ReviewLink)} />
          <Route
            path='/forgot-password'
            element={withSuspense(ForgotPassword)}
          />
          <Route
            path='/reset-password/:verifyId'
            element={withSuspense(ResetPassword)}
          />
          <Route
            path='/reset-password-success'
            element={withSuspense(ResetPasswordSuccess)}
          />
          <Route
            path='/reset-password-error'
            element={withSuspense(ResetPasswordError)}
          />
        </Route>
        <Route element={<RequireAuth />}>
          <Route path='/dashboard' element={withSuspense(EventsDashboard)} />
          <Route
            path='/ceremony/:ceremonyId/readings'
            element={
              <ReadingsProvider>
                <ReadingsModule />
              </ReadingsProvider>
            }
          />

          <Route
            path='/ceremony/:ceremonyId/logistics'
            element={<LogisticsModule />}
          />
          <Route
            path='/ceremony/:ceremonyId/ring-exchange'
            element={<RingExchangeModule />}
          />
          <Route
            path='/ceremony/:ceremonyId/love-story'
            element={<LoveStoryModule />}
          />
          <Route
            path='/ceremony/:ceremonyId/vows-ceremony'
            element={<VowsCeremonyModule />}
          />
          <Route
            path='/ceremony/:ceremonyId/pronouncement'
            element={<PronouncementModule />}
          />

          <Route path='/ceremony/:ceremonyId/vows' element={<VowsModule />} />
          <Route path='/ceremony/:ceremonyId/toast' element={<ToastModule />} />
          <Route
            path='/ceremony/:ceremonyId/welcome-toast'
            element={<WelcomeToastModule />}
          />
          <Route
            path={'/ceremony/:ceremonyId'}
            element={withSuspense(PageWrapper)}
          >
            <Route
              path={'/ceremony/:ceremonyId/checklist'}
              element={withSuspense(CeremonyChecklistDashboard)}
            />
            <Route
              path={'/ceremony/:ceremonyId/designer'}
              element={withSuspense(CeremonyDesignerLander)}
            />
            <Route
              path={'/ceremony/:ceremonyId/vowbuilder'}
              element={withSuspense(VowBuilderDashboard)}
            />
            <Route
              path={'/ceremony/:ceremonyId/toastbuilder'}
              element={withSuspense(ToastBuilderDashboard)}
            />
            <Route
              path={'/ceremony/:ceremonyId/planner'}
              element={withSuspense(PlannerDashboard)}
            />
            <Route
              path={'/ceremony/:ceremonyId/guest'}
              element={withSuspense(GuestDashboard)}
            />
            <Route
              path={'/ceremony/:ceremonyId/checklist-guest'}
              element={withSuspense(GuestChecklist)}
            />
            <Route
              path={'/ceremony/:ceremonyId/babylander'}
              element={withSuspense(BabyLander)}
            />
            <Route
              path={'/ceremony/:ceremonyId/speechwriting'}
              element={withSuspense(SpeechwritingLander)}
            />

            <Route
              path={'/ceremony/:ceremonyId/reflections'}
              element={withSuspense(ReflectionsLander)}
            />
            <Route
              path={'/ceremony/:ceremonyId/thankyounotes'}
              element={withSuspense(ThankYouNotesLander)}
            />

            <Route
              path={'/ceremony/:ceremonyId/'}
              element={withSuspense(RedirectHandler)}
            />
          </Route>
          <Route
            path='/ceremony/:ceremonyId/ordained'
            element={withSuspense(Ordained)}
          />
          <Route path={'/ceremony/planner'} element={withSuspense(PageWrapper)}>
            <Route
              path={'/ceremony/planner/dashboard'}
              element={withSuspense(PlannerOnboarding)}
            />
            <Route
              path={'/ceremony/planner/client-list'}
              element={withSuspense(ClientsList)}
            />
            <Route
              path={'/ceremony/planner/client-resources'}
              element={withSuspense(ClientsResources)}
            />
          </Route>

          <Route
            path='/public-speaking/:ceremonyId'
            element={withSuspense(Publicspeaking)}
          />
          <Route path='/modules/:ceremonyId' element={withSuspense(Modules)} />
          <Route path='/output/:ceremonyId' element={withSuspense(Output)} />
          <Route
            path='/preview-vows/:ceremonyId/:memberId'
            element={withSuspense(PreviewVows)}
          />
          <Route
            path='/preview-toast/:ceremonyId/:guestId'
            element={withSuspense(PreviewToast)}
          />
          <Route
            path='/preview-script/:ceremonyId'
            element={withSuspense(PreviewScript)}
          />
          <Route
            path='/ceremony-designer/:ceremonyId'
            element={
              <ReadingsProvider>
                {withSuspense(CeremonyBuilder)}
              </ReadingsProvider>
            }
          />
          <Route
            path='/vow-builder/:ceremonyId'
            element={withSuspense(VowBuilder)}
          />
          <Route
            path='/vow-builder-ai/:ceremonyId'
            element={withSuspense(VowBuilder)}
          />
          <Route
            path='/vow-builder-ai/:ceremonyId/:guestId'
            element={withSuspense(VowBuilder)}
          />
          <Route
            path='/welcome-toast-builder-ai/:ceremonyId/'
            element={withSuspense(WelcomeToastBuilder)}
          />
          <Route
            path='/export/output/:ceremonyId'
            element={withSuspense(Export)}
          />
          <Route
            path='/export/toast/:ceremonyId'
            element={withSuspense(ExportToast)}
          />
          <Route
            path='/export/toast-ai/:ceremonyId'
            element={withSuspense(ExportToastAi)}
          />
          <Route
            path='/export/vows/:ceremonyId'
            element={withSuspense(ExportVows)}
          />
          <Route
            path='/export/vows-ai/:ceremonyId'
            element={withSuspense(ExportVowsAI)}
          />
          <Route
            path='/toast-builder/:ceremonyId'
            element={withSuspense(Toast)}
          />
          <Route
            path='/ai-toast-builder/:ceremonyId'
            element={withSuspense(ToastBuilderNewest)}
          />
          <Route
            path='/survey-invite/:ceremonyId'
            element={withSuspense(SurveyInvite)}
          />
          <Route path='/referral' element={withSuspense(Referral)} />
          <Route path='/settings' element={withSuspense(Settings)} />
          <Route
            path='/pay-plans/:ceremonyid'
            element={withSuspense(PayPlans)}
          />
          <Route path='/wp-pay-plans/' element={withSuspense(WPPayPlans)} />
          <Route path='/wp-add-ceremony' element={withSuspense(AddCeremony)} />
          <Route
            path='/pay-merchandise/:ceremonyId/:appTool'
            element={withSuspense(PaywallMerchandising)}
          />
          <Route
            path='/pay-toastbuilder/:ceremonyId'
            element={withSuspense(PaywallToastBuilder)}
          />
          <Route
            path='/pay-details/:ceremonyid/:selectedCurrency'
            element={withSuspense(PayDetails)}
          />
          <Route
            path='/pay-summary/:ceremonyid'
            element={withSuspense(PaySummary)}
          />
          <Route
            path='/pay-status/:ceremonyid'
            element={withSuspense(PayStatus)}
          />
          <Route
            path='/waitlist/:ceremonyId/:module'
            element={withSuspense(Waitlist)}
          />
        </Route>
        <Route
          path='/stripe/payment-confirmation'
          element={withSuspense(PayConfirmation)}
        />
        <Route
          path='/stripe/subscription-confirmation'
          element={withSuspense(SubscriptionConfirmation)}
        />
        <Route
          path='/process-pay/rid/:refid/:reqid'
          element={withSuspense(ProcessPay)}
        />
        <Route path='/giftcard-send' element={withSuspense(GiftcardSend)} />

        <Route path='*' element={withSuspense(NotFound)} />
      </Routes>
      {/* I'm not sure why we have those */}
      {/* <BackgroundRoutes /> */}
    </React.Fragment>
  );
};

export default AppRoutes;
