import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { OrdainedModel } from '../../api/ordained';
import Button from '../../components/v2/Button/Button';
import { Typography } from '../../components/v2/Typography/Typography';
import { usaStates } from '../../data/states-cities';
import { getFirstName, getLastName } from '../../helpers/nameHelper';
import { useDashboard } from '../../provider/dashboardProvider';
import { CustomFormikCheckBox } from '../Modules/friendsAndFamily/CustomFormikCheckbox';
import { CustomFormikInput } from '../Modules/friendsAndFamily/CustomFormikInput';
import { CustomFormikSelect } from '../Modules/friendsAndFamily/CustomFormikSelect';

const OrdainedApplicationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  address: Yup.string().optional(),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  //age_confirmed, minister_requested, terms_accepted should all be true
  age_confirmed: Yup.boolean().oneOf(
    [true],
    'You must be at least 18 years old'
  ),
  minister_requested: Yup.boolean().oneOf(
    [true],
    'You must request to be a Provenance certified minister'
  ),
  terms_accepted: Yup.boolean().oneOf(
    [true],
    'You must accept the terms and conditions'
  ),
});

type OrdainedApplicationForm = {
  onSubmit: (values: OrdainedModel) => Promise<void>;
};

const OrdainedApplicationForm = (props: OrdainedApplicationForm) => {
  const { onSubmit } = props;

  const {currentUser} = useDashboard();

  const [initialValues, setInitialValues] = useState<OrdainedModel>({
    firstName: '',
    middleName: '',
    lastName: '',
    address: '',
    city: '',
    state: '',
    age_confirmed: false,
    minister_requested: false,
    terms_accepted: true,
  });

  useEffect(() => {
    if (currentUser) {
      setInitialValues({
        ...initialValues,
        firstName: getFirstName(currentUser.legal_name),
        lastName: getLastName(currentUser.legal_name),
      });
    }
  } , [currentUser]);

  return (
    <div className='container flex flex-col justify-center max-w-md px-4 sm:px-0 py-4'>
      <Typography type='display-600'>Ordination application</Typography>
      <Typography type='body-400' className='mt-4'>
        To become an ordained minister, complete the fom below:
      </Typography>
      <Formik
        validateOnMount={true}
        validateOnChange={true}
        validateOnBlur={true}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values) => {
          void onSubmit(values);
        }}
        validationSchema={OrdainedApplicationSchema}
      >
        {({ values, errors, touched, isValid }) => (
          <Form className='w-full mt-8'>
            <Field
              name={`firstName`}
              placeholder='Enter your first name'
              component={CustomFormikInput}
              label='First Name'
              required={true}
            />
            <Field
              name={`middleName`}
              placeholder='Enter your middle name'
              component={CustomFormikInput}
              label='Middle Name'
            />
            <Field
              name={`lastName`}
              placeholder='Enter your last name'
              component={CustomFormikInput}
              label='Last Name'
              required={true}
            />
            {/* <Field
              name={`address`}
              placeholder='Enter your street address'
              component={CustomFormikInput}
              label='Address'
              required={true}
            /> */}
            <div className='flex flex-row'>
              <div className='flex-1'>
                <Field
                  name={`city`}
                  placeholder='Enter your city'
                  component={CustomFormikInput}
                  label='City'
                  required={true}
                />
              </div>
              <div className='flex-1 ml-2'>
                <Field
                  name={`state`}
                  placeholder='Enter your state'
                  component={CustomFormikSelect}
                  label='State'
                  required={true}
                  options={usaStates.states.map((s) => {
                    return { value: s.name, label: s.name };
                  })}
                  error={touched.state && Boolean(errors.state)}
                  helperText={touched.state && errors.state}
                />
              </div>
            </div>

            <div className='mt-6'>
              <Field
                name={`age_confirmed`}
                component={CustomFormikCheckBox}
                label={
                  <Typography type='body-400' variant='functional-low'>
                    I confirm that I am at least 18 years of age, and that all of the information is truthful and accurate to the best of my knowledge
                  </Typography>
                }
              />
            </div>
            <div className='mt-6'>
              <Field
                name={`minister_requested`}
                component={CustomFormikCheckBox}
                label={
                  <Typography type='body-400' variant='functional-low'>
                   I request to be a certified minister of The Provenance Center. I undertake this request as a considered, deliberate, and responsible act
                  </Typography>
                }
              />
            </div>
            
            <div className='flex flex-row justify-center items-center'>
              <Button className='mt-6' type='submit' disabled={!isValid}>
                Get Ordained
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default OrdainedApplicationForm;
