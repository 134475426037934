
import { syncCustomerIo } from '../../api/ceremony';

import ReactGA from 'react-ga4';


import { OrdainedModel, saveOrdained } from '../../api/ordained';
import { Modal } from '../../components/v2/Modal/Modal';
import { sendCustomerIoEventHandler } from '../../helpers/customerIoHelper';
import { getAPIErrorMessage } from '../../helpers/helper';
import { useChecklist } from '../../provider/checklistProvider';
import { useDashboard } from '../../provider/dashboardProvider';
import { useSnackbar } from '../../provider/snackbarProvider';

import OrdainedApplicationForm from './OrdinationApplicationForm';

const OrdainedApplicationModal = () => {
  const {
    ordainedApplicationModalOpen,
    setOrdainedApplicationModalOpen,
    setOrdainedSuccessModalOpen,
    ceremony,
  } = useDashboard();

  const { openSnackBar } = useSnackbar();
  const { fetchChecklistItems } = useChecklist();

  const onSubmit = async (values: OrdainedModel) => {
    try {
      if (!ceremony) {
        return;
      }
      const response = await saveOrdained(values, ceremony.id);
      if (response.success) {


        ReactGA.event({
          category: 'Ordination',
          action: 'Ordination Finished',
          label: 'Ordination Finished',
        });

        setOrdainedApplicationModalOpen(false);
        setOrdainedSuccessModalOpen(true);
        if (response.success && ceremony) {

          void sendCustomerIoEventHandler('Gets Ordained', {
            name: `${values.firstName} ${values.lastName}`,
          });

          void syncCustomerIo({
            ceremonyId: ceremony.id,
          });

          void fetchChecklistItems(ceremony.id, false);
        }
      } else {
        openSnackBar(response.message, 'error');
      }
    } catch (err) {
      //hideLoader();
      openSnackBar(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getAPIErrorMessage(err as any),
        'error'
      );
    }

    setOrdainedApplicationModalOpen(false);
  };

  return (
    <Modal
      isOpen={ordainedApplicationModalOpen}
      onClose={() => setOrdainedApplicationModalOpen(false)}
    >
      <OrdainedApplicationForm onSubmit={onSubmit} />
    </Modal>
  );
};

export default OrdainedApplicationModal;
