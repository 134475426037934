import commentSvg from '../../pages/Ceremony/CeremonyChecklist/v2/images//comment.svg';
import faceTongueMoneySvg from '../../pages/Ceremony/CeremonyChecklist/v2/images//face-tongue-money.svg';
import featherSvg from '../../pages/Ceremony/CeremonyChecklist/v2/images//feather.svg';
import fileCertificateSvg from '../../pages/Ceremony/CeremonyChecklist/v2/images//file-certificate.svg';
import handHoldingHeartSvg from '../../pages/Ceremony/CeremonyChecklist/v2/images//hand-holding-heart.svg';
import handPointUpSvg from '../../pages/Ceremony/CeremonyChecklist/v2/images//hand-point-up.svg';
import messageSmileSvg from '../../pages/Ceremony/CeremonyChecklist/v2/images//message-smile.svg';
import microphoneSvg from '../../pages/Ceremony/CeremonyChecklist/v2/images//microphone.svg';
import userSvg from '../../pages/Ceremony/CeremonyChecklist/v2/images//user.svg';
import badgeCheckSvg from '../../pages/Ceremony/CeremonyChecklist/v2/images/badge-check.svg';
import booksSvg from '../../pages/Ceremony/CeremonyChecklist/v2/images/books.svg';
import champaigneGlassesSvg from '../../pages/Ceremony/CeremonyChecklist/v2/images/champagne-glasses.svg';
import circlePlay from '../../pages/Ceremony/CeremonyChecklist/v2/images/circle-play-2.svg';
import linkSvg from '../../pages/Ceremony/CeremonyChecklist/v2/images/link.svg';
import paintbrushPencilSvg from '../../pages/Ceremony/CeremonyChecklist/v2/images/paintbrush-pencil.svg';
import userPlusSvg from '../../pages/Ceremony/CeremonyChecklist/v2/images/user-plus.svg';

import { ChecklistItemIdentifier } from './identifiers';

export const getIconSrcByIdentifier = (identifier: string) => {
  switch (identifier) {
    case ChecklistItemIdentifier.CHECKOUT_CEREMONY_BUILDER:
      return paintbrushPencilSvg;
    case ChecklistItemIdentifier.INVITE_COLLABORATORS:
      return userSvg;
    case ChecklistItemIdentifier.GIFT_PROVENANCE:
      return userSvg;
    case ChecklistItemIdentifier.COMPLETE_CHAPTER_ONE:
      return paintbrushPencilSvg;
    case ChecklistItemIdentifier.GATHER_REFLECTIONS:
      return handHoldingHeartSvg;
    case ChecklistItemIdentifier.COMPLETE_CHAPTER_TWO:
      return paintbrushPencilSvg;
    case ChecklistItemIdentifier.COMPLETE_CHAPTER_THREE:
      return paintbrushPencilSvg;
    case ChecklistItemIdentifier.COMPLETE_CHAPTER_FOUR:
      return paintbrushPencilSvg;
    case ChecklistItemIdentifier.COMPLETE_CHAPTER_FIVE:
      return paintbrushPencilSvg;
    case ChecklistItemIdentifier.COMPLETE_CHAPTER_SIX:
      return paintbrushPencilSvg;
    case ChecklistItemIdentifier.FINALIZE_CEREMONY_SCRIPT:
      return paintbrushPencilSvg;
    case ChecklistItemIdentifier.START_VOWS:
      return commentSvg;
    case ChecklistItemIdentifier.REVIEW_PARTNER_A_VOWS:
      return commentSvg;
    case ChecklistItemIdentifier.REVIEW_PARTNER_B_VOWS:
      return commentSvg;
    case ChecklistItemIdentifier.INVITE_PARTNER_TO_VOWS:
      return commentSvg;
    case ChecklistItemIdentifier.WRITE_WELCOME_TOAST:
      return champaigneGlassesSvg;
    case ChecklistItemIdentifier.GET_SPEECHWRITING_HELP:
      return fileCertificateSvg;
    case ChecklistItemIdentifier.DOWNLOAD_PROCESSIONAL_GUIDE:
      return fileCertificateSvg;
    case ChecklistItemIdentifier.GIVE_ACCESS_TO_TOASTBUILDER:
      return champaigneGlassesSvg;
    case ChecklistItemIdentifier.REVIEW_SCRIPT_WITH_OFFICIANT:
      return paintbrushPencilSvg;
    case ChecklistItemIdentifier.REVIEW_SCRIPT_WITH_COUPLE:
      return paintbrushPencilSvg;
    case ChecklistItemIdentifier.DOWNLOAD_VOWS:
      return commentSvg;
    case ChecklistItemIdentifier.DOWNLOAD_SCRIPT:
      return paintbrushPencilSvg;
    case ChecklistItemIdentifier.GET_PUBLIC_SPEAKING_HELP:
      return microphoneSvg;
    case ChecklistItemIdentifier.DOWNLOAD_CHECKLIST_PDF:
      return handPointUpSvg;
    case ChecklistItemIdentifier.DOWNLOAD_PROCESSIONAL_TIPS_PDF:
      return handPointUpSvg;
    case ChecklistItemIdentifier.REFER_FRIENDS:
      return faceTongueMoneySvg;
    case ChecklistItemIdentifier.WRITE_THANK_YOU_NOTES:
      return featherSvg;
    case ChecklistItemIdentifier.GIVE_FEEDBACK:
      return messageSmileSvg;
    case ChecklistItemIdentifier.BUY_WEDDING_BOOK:
      return messageSmileSvg;
    case ChecklistItemIdentifier.PLANNER_PORTAL_DEMO:
      return circlePlay;
    case ChecklistItemIdentifier.INVITE_FIRST_CLIENT:
      return userPlusSvg;
    case ChecklistItemIdentifier.REVIEW_SPEECHES:
      return paintbrushPencilSvg;
    case ChecklistItemIdentifier.INVITE_TOAST_GIVERS:
      return champaigneGlassesSvg;
    case ChecklistItemIdentifier.EXPLORE_RESOURCES:
      return booksSvg;
    case ChecklistItemIdentifier.ADD_REST_OF_CLIENTS:
      return userPlusSvg;
    case ChecklistItemIdentifier.UNIQUE_LINK:
      return linkSvg;
    case ChecklistItemIdentifier.REFER_CLIENTS:
      return faceTongueMoneySvg;
    // Guest
    case ChecklistItemIdentifier.WRITE_TOAST:
      return champaigneGlassesSvg;
    case ChecklistItemIdentifier.GUEST_GET_SPEECHWRITING_HELP:
      return fileCertificateSvg;
    case ChecklistItemIdentifier.GUEST_GET_PUBLIC_SPEAKING_HELP:
      return microphoneSvg;
    case ChecklistItemIdentifier.REVIEW_TOAST_WITH_COUPLE:
      return champaigneGlassesSvg;
    case ChecklistItemIdentifier.DOWNLOAD_TOAST:
      return champaigneGlassesSvg;
    case ChecklistItemIdentifier.GUEST_REFER_FRIENDS:
      return faceTongueMoneySvg;
    case ChecklistItemIdentifier.GUEST_WATCH_DEMO:
      return circlePlay;
    case ChecklistItemIdentifier.GUEST_GIVE_FEEDBACK:
      return messageSmileSvg;
    case ChecklistItemIdentifier.ORDAINED:
      return badgeCheckSvg;

    default:
      return '';
  }
};

export const getIconBackgroundByIdentifier = (identifier: string) => {
  const plum200 = 'bg-[#CFCBD3]';
  const bgForest100 = 'bg-forest-100';
  const bgRose300 = 'bg-rose-300';
  const bgSeaFoam500 = 'bg-seaFoam-500';

  const plumIdentifiers = [
    ChecklistItemIdentifier.CHECKOUT_CEREMONY_BUILDER,
    ChecklistItemIdentifier.COMPLETE_CHAPTER_ONE,
    ChecklistItemIdentifier.COMPLETE_CHAPTER_TWO,
    ChecklistItemIdentifier.COMPLETE_CHAPTER_THREE,
    ChecklistItemIdentifier.COMPLETE_CHAPTER_FOUR,
    ChecklistItemIdentifier.COMPLETE_CHAPTER_FIVE,
    ChecklistItemIdentifier.COMPLETE_CHAPTER_SIX,
    ChecklistItemIdentifier.FINALIZE_CEREMONY_SCRIPT,
    ChecklistItemIdentifier.START_VOWS,
    ChecklistItemIdentifier.REVIEW_PARTNER_A_VOWS,
    ChecklistItemIdentifier.REVIEW_PARTNER_B_VOWS,
    ChecklistItemIdentifier.INVITE_PARTNER_TO_VOWS,
    ChecklistItemIdentifier.WRITE_WELCOME_TOAST,
    ChecklistItemIdentifier.GIVE_ACCESS_TO_TOASTBUILDER,
    ChecklistItemIdentifier.REVIEW_SCRIPT_WITH_OFFICIANT,
    ChecklistItemIdentifier.REVIEW_SCRIPT_WITH_COUPLE,
    ChecklistItemIdentifier.DOWNLOAD_VOWS,
    ChecklistItemIdentifier.DOWNLOAD_SCRIPT,
    //Guest
    ChecklistItemIdentifier.WRITE_TOAST,
    ChecklistItemIdentifier.REVIEW_TOAST_WITH_COUPLE,
    ChecklistItemIdentifier.DOWNLOAD_TOAST,
  ];

  const bgForestIdentifiers = [
    ChecklistItemIdentifier.INVITE_COLLABORATORS,
    ChecklistItemIdentifier.GET_SPEECHWRITING_HELP,
    ChecklistItemIdentifier.GET_PUBLIC_SPEAKING_HELP,
    ChecklistItemIdentifier.DOWNLOAD_CHECKLIST_PDF,
    ChecklistItemIdentifier.DOWNLOAD_PROCESSIONAL_TIPS_PDF,
    ChecklistItemIdentifier.REFER_FRIENDS,
    ChecklistItemIdentifier.GIVE_FEEDBACK,
    ChecklistItemIdentifier.BUY_WEDDING_BOOK,
    ChecklistItemIdentifier.PLANNER_PORTAL_DEMO,
    ChecklistItemIdentifier.INVITE_FIRST_CLIENT,
    ChecklistItemIdentifier.REVIEW_SPEECHES,
    ChecklistItemIdentifier.INVITE_TOAST_GIVERS,
    ChecklistItemIdentifier.EXPLORE_RESOURCES,
    ChecklistItemIdentifier.ADD_REST_OF_CLIENTS,
    ChecklistItemIdentifier.UNIQUE_LINK,
    ChecklistItemIdentifier.REFER_CLIENTS,
    ChecklistItemIdentifier.ORDAINED,
    ChecklistItemIdentifier.DOWNLOAD_PROCESSIONAL_GUIDE,
    ChecklistItemIdentifier.GIFT_PROVENANCE,
    //Guest
    ChecklistItemIdentifier.GUEST_GET_SPEECHWRITING_HELP,
    ChecklistItemIdentifier.GUEST_GET_PUBLIC_SPEAKING_HELP,
    ChecklistItemIdentifier.GUEST_REFER_FRIENDS,
    ChecklistItemIdentifier.GUEST_WATCH_DEMO,
    ChecklistItemIdentifier.GUEST_GIVE_FEEDBACK,
  ];

  const bgSeaFoamIdentifiers = [ChecklistItemIdentifier.WRITE_THANK_YOU_NOTES];

  const bgRoseIdentifiers = [ChecklistItemIdentifier.GATHER_REFLECTIONS];

  if (plumIdentifiers.includes(identifier as ChecklistItemIdentifier)) {
    return plum200;
  } else if (
    bgForestIdentifiers.includes(identifier as ChecklistItemIdentifier)
  ) {
    return bgForest100;
  } else if (
    bgSeaFoamIdentifiers.includes(identifier as ChecklistItemIdentifier)
  ) {
    return bgSeaFoam500;
  } else if (
    bgRoseIdentifiers.includes(identifier as ChecklistItemIdentifier)
  ) {
    return bgRose300;
  } else {
    return '';
  }
};
