/* eslint-disable */
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useMatch, useParams } from 'react-router-dom';

import { ModuleWrapper, QuestionType } from '../components';
import { SearchFilters } from '../../Modules/ritualsAndReadings/SearchFilters';
import {
  ReadingsProvider,
  Sort,
  useReadings,
} from '../../../provider/readingsProvider';
import DetailView from './DetailView';
import { Typography } from '../../../components/v2/Typography/Typography';
import { InputAdornment } from '@mui/material';
import searchSvg from '../../../assets/images/icons/search.svg';
import CustomTextInput from '../../../components/forms/CustomTextInput';
import { AddReadingModal } from '../../Modules/ritualsAndReadings/AddReadingModal';
import useCeremony from '../../../hooks/useCeremony';
import ReadingSearchItem from '../../Modules/ritualsAndReadings/ReadingSearchItem';
import useQuestions from '../../../hooks/useQuestions';
import { Module } from '../../../hooks/useActiveModule';
import { QuestionAnswer } from '../../Modules/common/moduleQuestion/ModuleQuestion';
import Button from '../../../components/v2/Button/Button';
import FiltersDrawer from '../../Modules/ritualsAndReadings/FiltersDrawer';
import { ConfirmRemoveRitualModal } from '../../Modules/ritualsAndReadings/ConfirmRemoveRitualModal';
import bookReaderIcon from '../../../assets/images/icons/book-open-reader.svg';
import { useCTA } from '../../../provider/ctaProvider';
import { ModuleStatus } from '../enum';
import { useGenerateModule } from '../../../provider/generateModuleProvider';
import { DropdownMultiselect } from '../../../components/v2/Dropdown/DropdownMultiselect';
import { Tooltip } from 'react-tooltip';
import ReactDOMServer from 'react-dom/server';
import CustomTooltip from '../../../components/v2/Tooltip/Tooltip';
import { useAuth } from '../../../provider/authProvider';
import { getCookie, setCookie } from '../../../helpers/helper';
import { updateUserCustomField } from '../../../api/user';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { useCeremonyBuilder } from '../../../provider/ceremonyBuilderProvider';
import { generalLog } from '../../../api/ceremony';

export const ReadingsModule = ({
  hideWrapper = false,
  onModuleStatusChange,
}: {
  hideWrapper?: boolean;
  onModuleStatusChange?: (status: ModuleStatus) => void;
}) => {
  const navigate = useNavigate();
  const isOpen = useMatch('/ceremony/:ceremonyId/readings');
  const params = useParams();

  const {
    searchValue,
    setSearchValue,
    filteredReadings,
    selectedRitual,
    setSelectedRitual,
    addModalOpen,
    setAddModalOpen,
    handleSaveNewRitual,
    fetchCeremony,
    tempAnswers,
    allReadings,
    setShowFilters,
    confirmRemoveOpen,
    ritualForRemoval,
    setConfirmRemoveOpen,
    removeRitual,
    saved,
    handleUseRitual,
    initRemoveRitual,
    currentUser,
    currentSort,
    setCurrentSort,
    showSortTooltip,
    setShowSortTooltip,
    lastRitualId,
    scrollRef,
  } = useReadings();

  const { user, getProfile } = useAuth();

  const { setDraftingModalOpen, setSelectedModule, setGenerateDraftInitiated } =
    useCeremonyBuilder();

  const size = useWindowSize();

  const [currentSlide, setCurrentSlide] = useState(0);

  const [activateScroll, setActivateScroll] = useState(false);

  const {
    setChapter,
    setNextChapterSlug,
    setCeremonyId,
    setModule,
    setTriggerGenerateScript,
  } = useGenerateModule();

  useEffect(() => {
    if (
      user &&
      user.custom_fields.filter(
        (f) => f.key === 'sorting-tooltip-done' && f.value === 'true'
      ).length === 0
    ) {
      const cookie = getCookie('sorting-tooltip-done');

      !cookie && setShowSortTooltip(true);
    }
  }, [user]);

  useEffect(() => {
    if (params.ceremonyId && !isNaN(parseInt(params.ceremonyId ?? ''))) {
      void fetchCeremony(params.ceremonyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.ceremonyId]);

  const getSelectedRitualIds = () => {
    const result: string[] = [];
    tempAnswers.forEach((ta) => {
      if (ta.option_id !== undefined) {
        result.push(ta.option_id);
      }
    });
    return result;
  };

  const selectedRitualIds = getSelectedRitualIds();
  const { setStatus } = useCTA();

  useEffect(() => {
    if (saved) {
      setStatus('saved');
    } else {
      setStatus('unsaved');
    }
  }, [saved]);

  useEffect(() => {
    if (onModuleStatusChange) {
      const moduleStatus =
        selectedRitualIds && selectedRitualIds.length > 0
          ? ModuleStatus.completed
          : ModuleStatus.inProgress;
      onModuleStatusChange(moduleStatus);
    }
  }, [selectedRitualIds]);

  useEffect(() => {
    scrollRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  }, [activateScroll]);

  const handleTooltipButtonClick = async () => {
    setShowSortTooltip(false);
    if (
      user &&
      user.custom_fields.filter(
        (f) => f.key === 'sorting-tooltip-done' && f.value === 'true'
      ).length === 0
    ) {
      try {
        await updateUserCustomField('sorting-tooltip-done', 'true');
        void getProfile(() => {});
        setCookie('sorting-tooltip-done', 'true', 2);
      } catch (error) {}
    }
  };

  const renderCompleteButton = () => (
    <Button
      size='100'
      onClick={() => {
        if (params.ceremonyId) {
          setSelectedModule(Module.RitualsAndReadings);
          setDraftingModalOpen(true);
          setGenerateDraftInitiated(true);
          generalLog({
            ceremony_id: params.ceremonyId,
            activity: `Generate - Readings & Rituals Module`,
          });
          navigate(`/ceremony-designer/${params.ceremonyId}`);
        } else {
          navigate(-1);
        }
      }}
    >
      Generate Section
    </Button>
  );

  const renderExitButton = () => (
    <Button
      size='100'
      variant='secondary'
      // disabled={selectedRitualIds && selectedRitualIds.length > 0}
      onClick={() => {
        if (params.ceremonyId)
          navigate(`/ceremony-designer/${params.ceremonyId}`);
        else navigate(-1);
      }}
    >
      Exit & Save
    </Button>
  );

  const renderDeselectButton = () => (
    <Button onClick={() => initRemoveRitual(selectedRitual!)}>Deselect</Button>
  );

  const renderBackButton = () => (
    <Button
      variant='secondary'
      onClick={() => {
        setSelectedRitual(undefined);
        setActivateScroll(!activateScroll);
      }}
    >
      Back To List
    </Button>
  );
  const renderSelectButton = () => (
    <Button
      disabled={false}
      onClick={() => {
        handleUseRitual(selectedRitual!.id);
        setActivateScroll(!activateScroll);
      }}
    >
      Select
    </Button>
  );

  const renderSkipButton = () => (
    <Button
      disabled={false}
      onClick={() => {
        if (params.ceremonyId)
          navigate(`/ceremony-designer/${params.ceremonyId}`);
        else navigate(-1);
      }}
    >
      Skip
    </Button>
  );

  let subHeadingText =
    currentUser && currentUser.member_type == 'officiant'
      ? 'Browse our extensive library of readings and rituals. You and your couple can select one or multiple options to include in their wedding ceremony – or skip this section entirely if you wish.'
      : 'Browse our extensive library of readings and rituals. Then, from the list below, select one or multiple options to include in your wedding ceremony.';
  return (
    <ModuleWrapper
      hideWrapper={hideWrapper}
      chapterProps={{
        chapter: 'Chapter 3',
        // status:
        //   selectedRitualIds && selectedRitualIds.length > 0
        //     ? ModuleStatus.completed
        //     : ModuleStatus.inProgress,
        renderIcon: () => <img src={bookReaderIcon} draggable={false} />,
      }}
      summaryProps={{
        moduleName: 'Readings & Rituals',
        moduleDescription: `Whether paying homage to cultural heritage, family history, religious tradition or secular inspiration, many couples choose to include 1 - 3 rituals or readings in their ceremony. These rites are also an opportunity to incorporate honored friends or family in the ceremony. With our community’s help, we are curating the very best library of rituals and readings, constantly expanding with an eye toward inclusivity and a global perspective.`,
        numberOfQuestions: undefined,
        estimatedTime: 20,
      }}
      questionsHeaderProps={{
        renderCenterButton: !selectedRitual
          ? selectedRitualIds && selectedRitualIds.length > 0
            ? renderCompleteButton
            : renderSkipButton
          : selectedRitual &&
            selectedRitual.id &&
            selectedRitualIds.indexOf(selectedRitual!.id.toString()) >= 0
          ? renderDeselectButton
          : renderSelectButton,
        renderRightButton: selectedRitual ? renderBackButton : renderExitButton,
      }}
      noGap={true}
    >
      {params.ceremonyId && !isNaN(parseInt(params.ceremonyId)) && (
        <AddReadingModal
          isOpen={addModalOpen}
          onClose={() => setAddModalOpen(false)}
          handleSave={handleSaveNewRitual}
          ceremonyId={parseInt(params.ceremonyId)}
        />
      )}
      <ConfirmRemoveRitualModal
        isOpen={confirmRemoveOpen && ritualForRemoval !== undefined}
        onClose={() => setConfirmRemoveOpen(false)}
        handleConfirm={() => {
          if (ritualForRemoval) {
            void removeRitual(ritualForRemoval?.id);
          }
        }}
      />
      {selectedRitual ? (
        <DetailView />
      ) : (
        <>
          <FiltersDrawer />
          <Typography type='display-200'>
            Readings and Rituals Library
          </Typography>
          <Typography type='body-400' className='mt-2 mb-10'>
            {subHeadingText}
          </Typography>
          <div className='flex flex-row justify-center items-center mb-6'>
            <CustomTextInput
              className='mx-0'
              value={searchValue}
              fullWidth={true}
              placeholder={'Search readings and rituals'}
              name={'search-input'}
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                setSearchValue(ev.target.value)
              }
            />
            {showSortTooltip && !size.isMobile && (
              <CustomTooltip anchorSelect='.my-anchor-element' place='bottom'>
                <div className='flex flex-col w-72'>
                  <Typography variant='functional-low' type='body-600'>
                    Sorting now available
                  </Typography>
                  <Typography
                    className='mt-2'
                    variant='functional-low'
                    type='body-200'
                  >
                    In addition to searching and filtering, you can now sort our
                    library alphabetically, by most popular, or by recently
                    added.
                  </Typography>
                  <Button
                    className='mt-4 w-24 bg-white text-forest-primary'
                    size='200'
                    variant='secondary'
                    onClick={() => handleTooltipButtonClick()}
                  >
                    Got it
                  </Button>
                </div>
              </CustomTooltip>
            )}

            <div className='my-anchor-element'>
              <DropdownMultiselect
                className='ml-2 mb-4 w-[185px] min-w-[185px] hidden md:block'
                options={Object.values(Sort).map((r) => ({
                  label: r,
                  value: r,
                }))}
                values={currentSort}
                multiselect={false}
                noSort={true}
                handleChange={(event) => {
                  const {
                    target: { value },
                  } = event;
                  setCurrentSort(
                    // On autofill we get a stringified value.
                    [value] as Sort[]
                  );
                }}
              />
            </div>
            {showSortTooltip && size.isMobile && (
              <CustomTooltip anchorSelect='.my-anchor-element-2' place='bottom'>
                <div className='flex flex-col w-72'>
                  <Typography variant='functional-low' type='body-600'>
                    Sorting now available
                  </Typography>
                  <Typography
                    className='mt-2'
                    variant='functional-low'
                    type='body-200'
                  >
                    In addition to searching and filtering, you can now sort our
                    library alphabetically, by most popular, or by recently
                    added.
                  </Typography>
                  <Button
                    className='mt-4 w-24 bg-white text-forest-primary'
                    size='200'
                    variant='secondary'
                    onClick={() => handleTooltipButtonClick()}
                  >
                    Got it
                  </Button>
                </div>
              </CustomTooltip>
            )}
            <div className='my-anchor-element-2'>
              <Button
                className='flex md:hidden ml-2 mb-4'
                onClick={() => setShowFilters(true)}
              >
                Sort & Filter
              </Button>
            </div>
          </div>
          <SearchFilters />
          <div className='w-full flex flex-row justify-between'>
            <Typography
              variant='functional-low'
              type='body-400'
              className='text-forest-300'
            >
              <>{filteredReadings.length} results</>
            </Typography>

            <Typography
              onClick={() => setAddModalOpen(true)}
              variant='functional-low'
              type='body-400'
              className='border-0 border-b border-dashed border-forest-primary cursor-pointer'
            >
              + Add Your Own
            </Typography>
          </div>

          <div className='flex flex-col sm:flex-row sm:flex-wrap gap-2 mt-6'>
            {!size.isMobile ? (
              allReadings
                .filter(
                  (fr) => selectedRitualIds.indexOf(fr.id.toString()) >= 0
                )
                .map((fr) => (
                  <ReadingSearchItem
                    ritual={fr}
                    key={fr.id}
                    isSelected={true}
                  />
                ))
            ) : (
              <ResponsiveCarousel
                className='interactiveCarousel'
                showArrows={false}
                swipeable
                emulateTouch
                centerMode
                centerSlidePercentage={80}
                autoPlay={false}
                showStatus={false}
                showIndicators={false}
                showThumbs={false}
                selectedItem={currentSlide}
                infiniteLoop={false}
                onChange={(index) => setCurrentSlide(index)}
              >
                {allReadings
                  .filter(
                    (fr) => selectedRitualIds.indexOf(fr.id.toString()) >= 0
                  )
                  .map((fr) => (
                    <ReadingSearchItem
                      ritual={fr}
                      key={fr.id}
                      isSelected={true}
                    />
                  ))}
              </ResponsiveCarousel>
            )}

            {filteredReadings &&
              filteredReadings.length > 0 &&
              filteredReadings
                .filter((fr) => selectedRitualIds.indexOf(fr.id.toString()) < 0)
                .map((fr) => (
                  <ReadingSearchItem
                    ritual={fr}
                    key={fr.id}
                    isSelected={false}
                  />
                ))}
          </div>

          {filteredReadings && filteredReadings.length === 0 && (
            <div className='w-full flex flex-row justify-center'>
              <div className=' max-w-sm mt-20 flex flex-col justify-center items-center text-center'>
                <Typography type='display-50'>No results found</Typography>
                <Typography type='body-400' className='mt-2 mb-6'>
                  Try again using more general search terms. If we don’t have
                  what you’re looking for, add your own reading and ritual to
                  our library.
                </Typography>
                <Button onClick={() => setAddModalOpen(true)}>
                  Add Your Own
                </Button>
              </div>
            </div>
          )}
        </>
      )}
    </ModuleWrapper>
  );
};
