import ReactGA from 'react-ga4';

import { Addon } from '../api/addons';
import { PayOption } from '../api/paywall';

export const fireAddToCartEvents = ({
  selectedAddons,
  plan,
  upsoldPlan,
  addons,
}: {
  selectedAddons: string[];
  plan: PayOption;
  upsoldPlan: PayOption | null;
  addons: Addon[];
}) => {
  let analyticsTotal = 0;
  analyticsTotal += parseFloat(plan.price.toString());
  analyticsTotal += upsoldPlan?.price
    ? 19.99
    : 0;

  const analyticsItems = [
    {
      id: plan.name,
      name: plan.name,
      brand: 'Provenance',
      quantity: 1,
      price: plan.price.toFixed(2),
    },
  ];

  if (selectedAddons.includes('community-reflections')) {
    analyticsTotal += 9.99;
    analyticsItems.push({
      id: 'Community Reflections',
      name: 'Community Reflections',
      brand: 'Provenance',
      quantity: 1,
      price: '9.99'
    });
  }

  if (selectedAddons.includes('thank-you-notes')) {
    analyticsTotal += 14.99;
    analyticsItems.push({
      id: 'Thank You Notes',
      name: 'Thank You Notes',
      brand: 'Provenance',
      quantity: 1,
      price: '14.99'
    });
  }

  if (upsoldPlan) {
    analyticsItems.push({
      id: upsoldPlan.name,
      name: upsoldPlan.name,
      brand: 'Provenance',
      quantity: 1,
      price: '19.99',
    });
  }

  ReactGA.event('add_to_cart', {
    value: analyticsTotal,
    currency: 'USD',
    items: analyticsItems,
  });

  ReactGA.event({
    category: 'PayWall',
    action: 'AddToCart',
    label: 'AddToCart',
  });
};
