import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { generalLog, getLog } from '../../../api/ceremony';
import heartComment from '../../../assets/images/icons/heartComment.svg';
import Button from '../../../components/v2/Button/Button';
import { Module } from '../../../hooks/useActiveModule';
import useCeremony from '../../../hooks/useCeremony';
import useQuestions from '../../../hooks/useQuestions';
import { CTAProvider } from '../../../provider/ctaProvider';
import { useVowBuilder } from '../../../provider/vowBuilderProvider';
import { ModuleQuestion, ModuleWrapper } from '../components';
import { GenerationIncentiveModal } from '../components/GenerationIncentiveModal/GenerationIncentiveModal';
import { GuiltModal } from '../components/GuiltModal/GuiltModal';
import GenerateButton from '../components/ModuleWrapper/GenerateButton';
import { ModuleStatus } from '../enum';

export const VowsModule = ({
  hideWrapper = false,
}: {
  hideWrapper?: boolean;
}) => {
  const navigate = useNavigate();
  const ceremonyId = useParams()?.ceremonyId;
  const [requiredQuestions, setRequiredQuestions] = useState(10);
  const [openGuiltModal, setOpenGuiltModal] = useState(false);
  const [openGenerationIncentiveModal, setOpenGenerationIncentiveModal] =
    useState(false);

  const {
    ceremony,
    couple1,
    couple2,
    officiant,
    currentUser,
    fetchCeremonyById,
  } = useCeremony();

  const members = { couple1, couple2, officiant, currentUser };

  const { setDraftingModalOpen, setGenerateDraftInitiated } = useVowBuilder();
  const vowsModule = useQuestions.Modules.VowsAi;
  const { questions, fetchQuestions } = useQuestions({
    ceremonyId,
    module: vowsModule,
  });

  const [isVbOpen, setIsOpenVb] = useState(false);

  useEffect(() => {
    ceremonyId && handleCheckVbUnlocked();
  }, [ceremonyId]);

  const handleCheckVbUnlocked = async () => {
    if (!ceremonyId) return false;

    try {
      const response = await getLog('Signup - Vows', ceremonyId.toString());

      if (response?.data) {
        setIsOpenVb(true);
        setRequiredQuestions(3);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (ceremonyId) {
      void fetchCeremonyById(ceremonyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ceremonyId]);

  useEffect(() => {
    if (ceremonyId) {
      void fetchQuestions(Module.VowsAi, ceremonyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ceremonyId]);

  const startGenerating = () => {
    if (ceremonyId) {
      setDraftingModalOpen(true);
      setGenerateDraftInitiated(true);

      void generalLog({
        ceremony_id: ceremonyId,
        activity: `Generate - VowsAI`,
      });

      navigate(`/vow-builder-ai/${ceremonyId}`);
    } else {
      navigate(-1);
    }
  };

  const handleGenerateClick = (moduleStatus: ModuleStatus) => {
    if (moduleStatus !== ModuleStatus.completed && !isVbOpen) {
      setOpenGuiltModal(true);
    } else {
      startGenerating();
    }
  };

  return (
    <CTAProvider
      totalQuestions={questions?.length || 0}
      requiredQuestions={requiredQuestions}
      module={Module.VowsAi}
    >
      <ModuleWrapper
        hideWrapper={hideWrapper}
        chapterProps={{
          renderIcon: () => <img src={heartComment} draggable={false} />,
        }}
        summaryProps={{
          moduleName: 'Vow Builder Questionnaire',
          moduleDescription: `Crafting your wedding vows is a personal and meaningful way to express your love, emotions, and promises on the big day. Our AI-powered questionnaire captures your unique stories and insights to draft vows that beautifully articulate your love and commitment.`,
          numberOfQuestions: 10,
          estimatedTime: 15,
          showAsteriskMeaning: true,
        }}
        questionsHeaderProps={{
          renderCenterButton: (
            moduleStatus: ModuleStatus,
            status: 'saving' | 'saved' | 'unsaved'
          ) => (
            <Button
              size='100'
              disabled={
                (moduleStatus !== ModuleStatus.completed &&
                  moduleStatus !== ModuleStatus.requiredAnswered) ||
                status !== 'saved'
              }
              onClick={() => {
                handleGenerateClick(moduleStatus);
              }}
            >
              Generate Vows
            </Button>
          ),
          renderRightButton: (moduleStatus: ModuleStatus) => (
            <Button
              size='100'
              variant='secondary'
              disabled={false}
              onClick={() => {
                if (ceremonyId) {
                  navigate(`/ceremony/${ceremonyId}/vowbuilder`);
                } else {
                  navigate(-1);
                }
              }}
            >
              Exit & Save
            </Button>
          ),
          setModuleStatus: (status) => {
            isVbOpen &&
              setOpenGenerationIncentiveModal(
                status === ModuleStatus.requiredAnswered
              );
          },
        }}
        noGap={true}
      >
        {ceremony &&
          questions &&
          questions.map((questionData, questionIndex) => {
            return (
              <ModuleQuestion
                key={`question-${questionData.id}`}
                ceremonyId={ceremony.id}
                question={questionData}
                questionNumber={questionIndex + 1}
                numberOfQuestions={questions?.length ?? 0}
                members={members}
                required={questionIndex < requiredQuestions}
              />
            );
          })}

        <GuiltModal
          isOpen={openGuiltModal}
          totalQuestions={questions ? questions.length : 0}
          onCancelClick={() => {
            setOpenGuiltModal(false);
          }}
          onContinue={startGenerating}
        />
        <GenerationIncentiveModal
          isOpen={openGenerationIncentiveModal}
          onCancelClick={() => {
            setOpenGenerationIncentiveModal(false);
          }}
          onContinue={startGenerating}
        />
        <GenerateButton
          fullWidth={true}
          onClickWithStatus={({ moduleStatus }) => {
            handleGenerateClick(moduleStatus);
          }}
          size='200'
          text='Generate Vows'
        />
      </ModuleWrapper>
    </CTAProvider>
  );
};
