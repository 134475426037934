/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useState } from 'react';

import { RedeemerApps, User } from '../api/auth';
import {
  Ceremony,
  GenericResponse,
  Member,
  MemberType,
  addUpdateCeremonyMember,
} from '../api/ceremony';
import { Giftcard } from '../api/giftcards';
import { CeremonySpeaker } from '../api/output';
import { sendCustomerIoEventHandler } from '../helpers/customerIoHelper';
import useCeremony from '../hooks/useCeremony';
import useGiftcards from '../hooks/useGiftcards';

import { useChecklist } from './checklistProvider';
import { useSnackbar } from './snackbarProvider';

export enum DashboardTab {
  Overview = 0,
  Ceremony = 1,
  Vows = 2,
  Toasts = 3,
}

export enum DashboardTabUrls {
  Overview = '/ceremony/general',
  Ceremony = '/ceremony/ceremony-modules',
  Vows = '/ceremony/vows',
  Toasts = '/ceremony/toasts',
}

export enum EventSettingsTab {
  General = 0,
  People = 1,
}

type DashboardContextType = {
  memberType?: MemberType;
  setMemberType: (value: MemberType | undefined) => void;

  addModalOpen: boolean;
  setAddModalOpen: (value: boolean) => void;

  chooseCollaboratorModalOpen: boolean;
  setChooseCollaboratorModalOpen: (value: boolean) => void;

  eventSettingsModalOpen: boolean;
  setEventSettingsModalOpen: (value: boolean) => void;

  memberForEditing?: Member;
  setMemberForEditing: (value: Member | undefined) => void;

  selectedDashboardTab: DashboardTab;
  setSelectedDashboardTab: (value: DashboardTab) => void;

  selectedEventSettingsTab: EventSettingsTab;
  setSelectedEventSettingsTab: (value: EventSettingsTab) => void;

  mobileMenuExpanded: boolean;
  setMobileMenuExpanded: (value: boolean) => void;

  handleAddMember: (member: Member) => void;
  handleAddMemberClick: (
    type: MemberType,
    weddingPlanner?: Member,
    partner?: Member
  ) => void;
  ceremony?: Ceremony;
  couple1?: Member;
  couple2?: Member;
  officiant?: Member;
  currentRole?: MemberType;
  currentUser?: Member;
  weddingPlanner?: Member;
  fetchCeremony: (ceremonyId: string) => void;
  speakingEvents: CeremonySpeaker[];
  giftcard?: Giftcard;
  fetchGiftcard: () => void;
  showRedeeemGiftcardModal: boolean;
  setShowRedeeemGiftcardModal: (value: boolean) => void;
  showDeactivateModal: boolean;
  setShowDeactivateModal: (value: boolean) => void;
  setWPUserAsCurrentUser: (user: User) => void;
  youtubeVideoModalOpen: boolean;
  setYoutubeVideoModalOpen: (value: boolean) => void;
  youtubeVideoUrl: string;
  setYoutubeVideoUrl: (value: string) => void;
  isReferralModalOpen: boolean;
  setIsReferralModalOpen: (value: boolean) => void;
  enterPasswordModalOpen: boolean;
  setEnterPasswordModalOpen: (value: boolean) => void;
  verifyCodeModalOpen: boolean;
  setVerifyCodeModalOpen: (value: boolean) => void;
  createNewPasswordModalOpen: boolean;
  setCreateNewPasswordModalOpen: (value: boolean) => void;
  redeemerApp?: RedeemerApps;
  setRedeemerApp: (redeemerApp: RedeemerApps) => void;
  ordainedInfoModalOpen: boolean;
  setOrdainedInfoModalOpen: (value: boolean) => void;
  ordainedApplicationModalOpen: boolean;
  setOrdainedApplicationModalOpen: (value: boolean) => void;
  ordainedSuccessModalOpen: boolean;
  setOrdainedSuccessModalOpen: (value: boolean) => void;
};

export const DashboardContext = React.createContext<DashboardContextType>({
  memberType: MemberType.officiant,
  setMemberType: () => {},

  addModalOpen: false,
  setAddModalOpen: () => {},

  chooseCollaboratorModalOpen: false,
  setChooseCollaboratorModalOpen: () => {},

  mobileMenuExpanded: false,
  setMobileMenuExpanded: () => {},

  eventSettingsModalOpen: false,
  setEventSettingsModalOpen: () => {},

  memberForEditing: {} as Member,
  setMemberForEditing: () => {},

  selectedDashboardTab: DashboardTab.Ceremony,
  setSelectedDashboardTab: () => {},

  selectedEventSettingsTab: EventSettingsTab.General,
  setSelectedEventSettingsTab: () => {},

  handleAddMember: () => {},
  handleAddMemberClick: () => {},
  ceremony: {} as Ceremony,
  couple1: {} as Member,
  couple2: {} as Member,
  officiant: {} as Member,
  currentRole: MemberType.bride,
  currentUser: {} as Member,
  weddingPlanner: {} as Member,
  fetchCeremony: () => {},
  speakingEvents: [],
  giftcard: {} as Giftcard,
  fetchGiftcard: () => {},
  showRedeeemGiftcardModal: false,
  setShowRedeeemGiftcardModal: () => {},

  showDeactivateModal: false,
  setShowDeactivateModal: () => {},
  setWPUserAsCurrentUser: () => {},
  youtubeVideoModalOpen: false,
  setYoutubeVideoModalOpen: () => {},
  youtubeVideoUrl: '',
  setYoutubeVideoUrl: () => {},
  isReferralModalOpen: false,
  setIsReferralModalOpen: () => {},
  enterPasswordModalOpen: false,
  setEnterPasswordModalOpen: () => {},
  verifyCodeModalOpen: false,
  setVerifyCodeModalOpen: () => {},
  createNewPasswordModalOpen: false,
  setCreateNewPasswordModalOpen: () => {},
  redeemerApp: RedeemerApps.CEREMONIES,
  setRedeemerApp: () => {},
  ordainedInfoModalOpen: false,
  setOrdainedInfoModalOpen: () => {},
  ordainedApplicationModalOpen: false,
  setOrdainedApplicationModalOpen: () => {},
  ordainedSuccessModalOpen: false,
  setOrdainedSuccessModalOpen: () => {},
});

export const DashboardProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [youtubeVideoModalOpen, setYoutubeVideoModalOpen] = useState(false);
  const [youtubeVideoUrl, setYoutubeVideoUrl] = useState('');
  const [addModalOpen, setModalOpen] = useState(false);
  const [chooseCollaboratorModalOpen, setChooseCollaboratorModalOpen] =
    useState(false);
  const [mobileMenuExpanded, setMobileMenuExpanded] = useState(false);
  const [eventSettingsModalOpen, setSettingsModalOpen] = useState(false);
  const [memberForEditing, setMember] = useState<Member | undefined>(
    {} as Member
  );
  const [memberType, setType] = useState<MemberType | undefined>();
  const [selectedDashboardTab, setDashboardTab] = useState<DashboardTab>(
    DashboardTab.Overview
  );
  const [selectedEventSettingsTab, setEventSettingsTab] =
    useState<EventSettingsTab>(EventSettingsTab.General);
  const [showRedeeemGiftcardModal, setShowRedeeemGiftcardModal] =
    useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);

  const [isReferralModalOpen, setIsReferralModalOpen] = useState(false);
  const [enterPasswordModalOpen, setEnterPasswordModalOpen] = useState(false);
  const [verifyCodeModalOpen, setVerifyCodeModalOpen] = useState(false);
  const [createNewPasswordModalOpen, setCreateNewPasswordModalOpen] =
    useState(false);
  const [redeemerApp, setRedeemerApp] = useState<RedeemerApps>(
    RedeemerApps.CEREMONIES
  );
  const [ordainedInfoModalOpen, setOrdainedInfoModalOpen] = useState(false);
  const [ordainedApplicationModalOpen, setOrdainedApplicationModalOpen] =
    useState(false);
  const [ordainedSuccessModalOpen, setOrdainedSuccessModalOpen] =
    useState(false);

  const { openSnackBar } = useSnackbar();
  const { fetchChecklistItems } = useChecklist();
  const {
    ceremony,
    couple1,
    couple2,
    currentRole,
    currentUser,
    weddingPlanner,
    officiant,
    speakingEvents,
    fetchCeremonyById,
    setWPUserAsCurrentUser,
  } = useCeremony();

  const { giftcard, fetchGiftcard } = useGiftcards();

  const setMemberForEditing = (member: Member | undefined) => {
    setMember(member);
  };

  const setAddModalOpen = (value: boolean) => {
    setModalOpen(value);
  };
  const setEventSettingsModalOpen = (value: boolean) => {
    setSettingsModalOpen(value);
  };

  const setMemberType = (value: MemberType | undefined) => {
    setType(value);
  };

  const setSelectedDashboardTab = (value: DashboardTab) => {
    setDashboardTab(value);
  };

  const setSelectedEventSettingsTab = (value: EventSettingsTab) => {
    setEventSettingsTab(value);
  };

  const fetchCeremony = (ceremonyId: string) => {
    if (ceremonyId && !isNaN(parseInt(ceremonyId ?? ''))) {
      void fetchCeremonyById(ceremonyId);
    }
  };

  const handleAddMemberClick = (
    type: MemberType,
    weddingPlanner?: Member,
    partner?: Member
  ) => {
    setMemberType(type);
    setEventSettingsModalOpen(false);
    if (type === MemberType.weddingPlanner && weddingPlanner) {
      setMemberForEditing(weddingPlanner);
    } else if (
      type === MemberType.bride ||
      type === MemberType.groom ||
      type === MemberType.other
    ) {
      if (partner) {
        setMemberForEditing(partner);
      }
    }
    setAddModalOpen(true);
  };

  const handleAddMember = async (member: Member) => {
    if (ceremony) {
      try {
        const response: GenericResponse = await addUpdateCeremonyMember(
          ceremony.id.toString(),
          member
        );
        if (response.success) {
          void sendCustomerIoEventHandler(
            'Invite Received',
            {
              sender_name: `${currentUser?.legal_name ?? ''}`,
              sender_type: `${currentUser?.member_type ?? ''}`,
              partner_a_name: `${couple1?.legal_name ?? ''}`,
              partner_b_name: `${couple2?.legal_name ?? ''}`,
              recipient_email: member.email,
              recipient_name: member.legal_name ?? member.preferred_name ?? '',
              recipient_type: member.member_type ?? '',
              invite_status: member.user_id ? 'accepted' : 'pending',
            },
            member.email
          );

          void sendCustomerIoEventHandler('Invite Sent', {
            sender_name: `${currentUser?.legal_name ?? ''}`,
            sender_type: `${currentUser?.member_type ?? ''}`,
            partner_a_name: `${couple1?.legal_name ?? ''}`,
            partner_b_name: `${couple2?.legal_name ?? ''}`,
            recipient_email: member.email,
            recipient_name: member.legal_name ?? member.preferred_name ?? '',
            recipient_type: member.member_type ?? '',
            invite_status: member.user_id ? 'accepted' : 'pending',
          });

          void fetchChecklistItems(ceremony.id, false);

          openSnackBar(
            member.id
              ? `Ceremony Member has been edited`
              : `Ceremony Member has been invited`
          );
          void fetchCeremonyById(ceremony.id.toString());
        } else {
          openSnackBar(`Something went wrong`, 'error');
        }
      } catch (err) {
        openSnackBar(`Something went wrong`, 'error');
      }
    }
  };

  const value = {
    memberType,
    setMemberType,
    memberForEditing,
    setMemberForEditing,
    addModalOpen,
    setAddModalOpen,
    chooseCollaboratorModalOpen,
    setChooseCollaboratorModalOpen,
    eventSettingsModalOpen,
    setEventSettingsModalOpen,
    selectedDashboardTab,
    setSelectedDashboardTab,
    selectedEventSettingsTab,
    setSelectedEventSettingsTab,
    handleAddMember,
    handleAddMemberClick,
    ceremony,
    couple1,
    couple2,
    officiant,
    currentRole,
    currentUser,
    weddingPlanner,
    fetchCeremony,
    speakingEvents,
    mobileMenuExpanded,
    setMobileMenuExpanded,
    giftcard,
    fetchGiftcard,
    showRedeeemGiftcardModal,
    setShowRedeeemGiftcardModal,
    showDeactivateModal,
    setShowDeactivateModal,
    setWPUserAsCurrentUser,
    youtubeVideoModalOpen,
    setYoutubeVideoModalOpen,
    youtubeVideoUrl,
    setYoutubeVideoUrl,
    isReferralModalOpen,
    setIsReferralModalOpen,
    enterPasswordModalOpen,
    setEnterPasswordModalOpen,
    verifyCodeModalOpen,
    setVerifyCodeModalOpen,
    createNewPasswordModalOpen,
    setCreateNewPasswordModalOpen,
    redeemerApp,
    setRedeemerApp,
    ordainedInfoModalOpen,
    setOrdainedInfoModalOpen,
    ordainedApplicationModalOpen,
    setOrdainedApplicationModalOpen,
    ordainedSuccessModalOpen,
    setOrdainedSuccessModalOpen,
  };
  // @ts-ignore
  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = () => {
  return useContext(DashboardContext);
};
