/* eslint-disable -- TODO: fix eslint errors */

import { Checkbox } from '@mui/material';
import checked from '../../assets/images/icons/checkbox-checked-v2.svg';
import unchecked from '../../assets/images/icons/checkbox-unchecked-v2.png';
import classNames from 'classnames';

const CustomCheckBox = ({
  label,
  bold = true,
  customClassName,
  reverseColors = false,
  onChange = () => null,
  ...props
}: any) => {
  return (
    <div className={`flex flex-row items-center  ${customClassName}`}>
      <label
        className={classNames(
          { 'font-semibold': bold },
          'flex flex-row items-center cursor-pointer'
        )}
      >
        <Checkbox
          {...props}
          className={reverseColors ? 'text-white' : 'text-forest-primary'}
          sx={{
            display: 'block',
            color: reverseColors ? '#ffffff' : '#354740',
            '&.Mui-checked': {
              color: reverseColors ? '#ffffff' : '#354740',
            },
          }}
          icon={
            <img className='w-[22px] h-[22px] max-w-none' src={unchecked} />
          }
          onChange={(ev) => onChange(ev)}
          checkedIcon={
            <img className='w-[22px] h-[22px] max-w-none' src={checked} />
          }
        />

        <div> {props.customLabel ? props.customLabel : label}</div>
      </label>
    </div>
  );
};

export default CustomCheckBox;
