/* eslint-disable -- TODO: fix eslint errors */

import { ListItemIcon, MenuItem, Select, TextField } from '@mui/material';
import { Typography } from '../v2/Typography/Typography';

import './customDropdown.scss';
import { Check } from '@mui/icons-material';

const CustomDropdown = ({
  value,
  onChange,
  error,
  helper,
  placeholder,
  startAdornment,
  endAdornment,
  name,
  options = [],
  type = 'text',
  noSort = false,
  label = '',
  ...props
}: any) => {
  const sortFunction = (a: any, b: any) => {
    if (noSort) {
      return 0;
    } else {
      if (a.label == 'Other') return 1;
      if (b.label == 'Other') return -1;
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    }
  };

  return (
    <div className='mb-4'>
      {label && (
        <div className='mb-2'>
          <label>
            <Typography
              variant='functional-low'
              type='body-200'
              className='inline'
            >
              <>{label}</>
            </Typography>
            {props.required && (
              <Typography
                variant='functional-low'
                type='body-200'
                className='ml-1 inline text-copper-primary'
              >
                (Required)
              </Typography>
            )}
          </label>
        </div>
      )}
      <Select
        value={value}
        onChange={onChange}
        autoWidth={false}
        variant='outlined'
        className='w-full'
        placeholder={placeholder}
        name={name}
        error={error}
        defaultValue=''
        displayEmpty
        renderValue={(selected: any) => {
          if (selected.length === 0) {
            return <p className='text-gray-500'>{placeholder}</p>;
          }
          return (
            options.find((x: any) => x.value === selected).label || selected
          );
        }}
        {...props}
      >
        {placeholder && (
          <MenuItem disabled value=''>
            <em>{placeholder}</em>
          </MenuItem>
        )}
        {options
          .sort((a: any, b: any) => sortFunction(a, b))
          .map((option: any, i: any) => {
            return (
              <MenuItem key={i} value={option.value}>
                <Typography
                  className='mr-2'
                  variant='functional-low'
                  type='body-400'
                >
                  {option.label}
                </Typography>
                {option.value === value && (
                  <ListItemIcon>
                    <Check />
                  </ListItemIcon>
                )}
              </MenuItem>
            );
          })}
      </Select>
    </div>
  );
};

export default CustomDropdown;
