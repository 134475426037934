/* eslint-disable */
import { useNavigate, useParams } from 'react-router-dom';

import { ModuleQuestion, ModuleWrapper } from '../components';

import unionIcon from '../../../assets/images/icons/union.svg';
import useQuestions from '../../../hooks/useQuestions';
import useDeclarations from '../../../hooks/useDeclarations';
import useCeremony from '../../../hooks/useCeremony';
import usePronouncements from '../../../hooks/usePronouncements';
import { ModuleStatus } from '../enum';
import Button from '../../../components/v2/Button/Button';
import { Module } from '../../../hooks/useActiveModule';
import { useCeremonyBuilder } from '../../../provider/ceremonyBuilderProvider';
import { useEffect } from 'react';
import { generalLog } from '../../../api/ceremony';
import { CTAProvider } from '../../../provider/ctaProvider';
import { transformQuestionOptions } from '../components/ModuleQuestion/helpers';
import GenerateButton from '../components/ModuleWrapper/GenerateButton';

export const PronouncementModule = ({
  hideWrapper = false,
  onModuleStatusChange,
}: {
  hideWrapper?: boolean;
  onModuleStatusChange?: (status: ModuleStatus) => void;
}) => {
  const ceremonyId = useParams()?.ceremonyId;

  const { setDraftingModalOpen, setSelectedModule, setGenerateDraftInitiated } =
    useCeremonyBuilder();

  const { questions, fetchQuestions } = useQuestions();
  const { declarations } = useDeclarations();
  const { pronouncements } = usePronouncements();
  const navigate = useNavigate();

  const {
    ceremony,
    couple1,
    couple2,
    officiant,
    currentUser,
    fetchCeremonyById,
  } = useCeremony();
  const members = { couple1, couple2, officiant, currentUser };

  useEffect(() => {
    if (ceremonyId) {
      void fetchCeremonyById(ceremonyId);
    }
  }, [ceremonyId]);

  useEffect(() => {
    if (ceremony) {
      void fetchQuestions(
        Module.PronouncementCelebration,
        ceremony.id.toString()
      );
    }
  }, [ceremony]);

  const handleGenerateClick = () => {
    if (ceremonyId) {
      setSelectedModule(Module.PronouncementCelebration);
      setDraftingModalOpen(true);
      setGenerateDraftInitiated(true);
      generalLog({
        ceremony_id: ceremonyId,
        activity: `Generate - Pronouncement, Kiss & Celebration Module`,
      });
      navigate(`/ceremony-designer/${ceremonyId}`);
    } else {
      navigate(-1);
    }
  };

  return (
    <CTAProvider
      totalQuestions={questions?.length || 0}
      requiredQuestions={questions?.length || 0}
      onModuleStatusChange={onModuleStatusChange}
      module={Module.PronouncementCelebration}
    >
      <ModuleWrapper
        hideWrapper={hideWrapper}
        chapterProps={{
          chapter: 'Chapter 6',
          renderIcon: () => <img src={unionIcon} draggable={false} />,
          iconWrapperProps: { className: 'bg-cashmere-600' },
        }}
        summaryProps={{
          moduleName: 'Pronouncement, Kiss & Celebration',
          moduleDescription:
            'The moment we have all been waiting for! While the grand finale often follows a typical structure, you should align on what specific phrasing you’d prefer. Including a pronouncement may be legally required, depending on your location.',
          numberOfQuestions: 2,
          estimatedTime: 3,
        }}
        questionsHeaderProps={{
          renderCenterButton: () => (
            <GenerateButton
              onClick={() => {
                handleGenerateClick();
              }}
              size='100'
            />
          ),
          renderRightButton: (moduleStatus: ModuleStatus) => (
            <Button
              size='100'
              variant='secondary'
              onClick={() => {
                if (ceremonyId) {
                  navigate(`/ceremony-designer/${ceremonyId}`);
                } else {
                  navigate(-1);
                }
              }}
            >
              Exit & Save
            </Button>
          ),
        }}
      >
        {ceremony &&
          questions &&
          questions.map((questionData, questionIndex) => {
            return (
              <ModuleQuestion
                key={`question-${questionData.id}`}
                ceremonyId={ceremony.id}
                question={questionData}
                questionNumber={questionIndex + 1}
                numberOfQuestions={questions?.length ?? 0}
                members={members}
                options={transformQuestionOptions(
                  questionData,
                  declarations || [],
                  pronouncements || []
                )}
              />
            );
          })}

        <GenerateButton
          fullWidth={true}
          onClick={() => {
            handleGenerateClick();
          }}
          size='200'
        />
      </ModuleWrapper>
    </CTAProvider>
  );
};
