import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';

import { Member, MemberType, generalLog } from '../../../api/ceremony';
import { EventType, Speaker } from '../../../api/friends';
import { OrderSources } from '../../../api/paywall';
import { addSpeaker } from '../../../api/speakers';
import { PaywallModal, UpsellGifTypes } from '../../../components/PaywallModal/PaywallModal';
import SnackbarComponent from '../../../components/Snackbar/Snackbar';
import { AddGuestSpeakerModal } from '../../../components/v2/AddGuestSpeakerModal/AddGuestSpeakerModal';
import Button from '../../../components/v2/Button/Button';
import ButtonPill from '../../../components/v2/ButtonPill';
import { DraggableToastList } from '../../../components/v2/DraggableToastList/DraggableToastList';
import EmptyState from '../../../components/v2/EmptyState/EmptyState';
import { Separator } from '../../../components/v2/Separator/Separator';
import { Typography } from '../../../components/v2/Typography/Typography';
import { VowsDashboardItem } from '../../../components/v2/VowsDashboardItem/VowsDashboardItem';
import { sendCustomerIoEventHandler } from '../../../helpers/customerIoHelper';
import {
  getAPIErrorMessage,
  getCookie,
  setCookie,
} from '../../../helpers/helper';
import renderText from '../../../helpers/renderText';
import useLoader from '../../../hooks/useLoader';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { useSpeakers, Speaker as EventSpeaker } from '../../../hooks/useSpeakers';
import useToast from '../../../hooks/useToast';
import useVows from '../../../hooks/useVows';
import { useDashboard } from '../../../provider/dashboardProvider';
import { useAccessibleModule } from '../../../provider/moduleAccessProvider';
import { usePaywall } from '../../../provider/paywallProvider';

import LockSvg from './images/lock.svg';

export const ToastBuilderDashboard = () => {
  const { ceremony, currentUser } = useDashboard();
  const { fetchWelcomeToast, welcomeToast } = useToast();
  const [modalOpen, setModalOpen] = useState(false);
  const { isActive, message, type, openSnackBar, handleClose } = useSnackbar();
  const { hideLoader } = useLoader();
  const { hasAccessToInviteGuests, fetchingAccessibleModulesInProgress } =
    useAccessibleModule();

  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState(false);
  const [referrer, setReferrer] = useState('toast');
  const [currentSpeaker, setCurrentSpeaker] = useState<Speaker>({
    member: {} as Member,
  } as Speaker);

  const [upsellGif, setUpsellGif] = useState<UpsellGifTypes | undefined>( undefined );

  const { hasAccessToWelcomeToast, fetchAccessibleModulesIdentifiers } =
    useAccessibleModule();
  const { fetchSpeakers, speakerEvents } = useSpeakers({
    ceremonyId: ceremony?.id ?? 0,
  });
  const { setSource } = usePaywall();

  const navigate = useNavigate();

  useEffect(() => {
    if (ceremony) {
      void fetchWelcomeToast(ceremony.id.toString());
    }
    if (ceremony && currentUser) {
      setCookie('last_active_ceremony', ceremony.id.toString(), 365);
      void fetchWelcomeToast(ceremony.id.toString());
      void fetchAccessibleModulesIdentifiers(ceremony.id.toString());
    }
  }, [ceremony]);

  const handleAddSpeaker = async (speaker: Speaker) => {
    setModalOpen(false);
    if (ceremony) {
      try {
        const response = await addSpeaker(ceremony.id.toString(), speaker);
        if (response.success) {
          void sendCustomerIoEventHandler('Guest Invited', {
            name: speaker.member.legal_name,
            email: speaker.member.email,
            relation: speaker.member.member_sub_type,
            event: speaker.event,
            speaking_duration: speaker.alloted_time,
          });
          openSnackBar('Saved successfully');
          hideLoader();
          void fetchSpeakers();
        } else {
          hideLoader();
          openSnackBar(response.message, 5000, 'error');
        }
      } catch (err) {
        console.log(err);
        hideLoader();
        openSnackBar(getAPIErrorMessage(err), 5000, 'error');
      }
    }
  };

  const onUpgradeButtonClick = () => {
    if (ceremony) {
      ReactGA.event({
        category: 'PayWall',
        action: 'Unlock',
        label: 'Toasts-Welcome-Toast',
      });
      setSource(OrderSources.tb_landing_welcome_toast);
      generalLog({
        ceremony_id: ceremony.id,
        activity: `Unlock - Vows`,
      })
        .then(() => {
          setReferrer('toast');
          setUpsellGif(UpsellGifTypes.welcomeToastBuilder);
          setIsPaywallModalOpen(true);
        })
        .catch((err) => console.error(err));
    }
  };

  return (
    <React.Fragment>
      <SnackbarComponent
        isActive={isActive}
        message={message}
        type={type}
        handleClose={handleClose}
      />
      <div className=' flex flex-1 flex-col items-stretch h-full mt-8 lg:mt-0'>
        <div className='flex flex-col lg:flex-row justify-between'>
          <div className='flex flex-col gap-1 max-w-lg'>
            <Typography type='display-400'>Toast Builder</Typography>
            <Typography type='body-400'>
              {renderText('toast_builder_description')}
            </Typography>
          </div>
        </div>
        <div>
          {currentUser && currentUser.member_type !== MemberType.officiant && (
            <div className='mt-10 flex flex-col gap-5'>
              <h3 className='font-recife text-xl sm:text-2xl'>Your Toast</h3>

              <VowsDashboardItem
                variant='welcome_toast'
                canEditVows={true}
                ceremonyId={ceremony?.id.toString() || '0'}
                title='Welcome Toast'
                vows={welcomeToast}
                text='Nervous you’ll forget something (or someone) in your Welcome Toast? Answer these curated questions and get a great first draft in seconds.'
                member={currentUser}
                vowsTime={0}
                hasAccess={hasAccessToWelcomeToast}
                showVows={true}
                showLength={false}
                showTone={false}
                onUpgradeButtonClick={() => {
                  typeof onUpgradeButtonClick === 'function' &&
                    onUpgradeButtonClick();
                  if (ceremony?.id) {
                    ({
                      ceremony_id: ceremony.id,
                      activity: `Upgrade - ToastBuilder - v1`,
                    });
                  }
                }}
                onButtonClick={() => {
                  if (ceremony?.id) {
                    welcomeToast
                      ? navigate(
                          `/welcome-toast-builder-ai/${ceremony.id.toString()}`
                        )
                      : navigate(
                          `/ceremony/${ceremony.id.toString()}/welcome-toast`
                        );
                  }
                }}
              />
            </div>
          )}
        </div>
        {speakerEvents.length === 0 ? (
          <div className='mt-10'>
            <Typography type='display-400' className='mb-4'>
              All Toasts
            </Typography>
    
            {!fetchingAccessibleModulesInProgress &&
              hasAccessToInviteGuests && (
                <EmptyState
                  title='No guest speakers yet'
                  text={renderText('toast_builder_empty_state_text')}
                  buttonText='Add Guest Speaker'
                  onClick={() => setModalOpen(true)}
                  background='grey'
                />
              )}

            {!fetchingAccessibleModulesInProgress && !hasAccessToInviteGuests && (
              <EmptyState
                title='Want your guests to crush their toasts?'
                text={`Provide access to our Toast Builder as well as speech instructions for all your speakers.`}
                buttonText='Upgrade to unlock'
                onClick={() => {
                  setReferrer('guests');
                  setUpsellGif(UpsellGifTypes.toastBuilder);
                  setIsPaywallModalOpen(true);
                }}
                customIcon={LockSvg}
                customIconWidth='w-20'
                background='grey'
              />
            )}

            <AddGuestSpeakerModal
              title='Add Guest Speaker'
              isOpen={modalOpen}
              onClose={() => setModalOpen(false)}
              initialSpeaker={{member:{} as Member} as Speaker}
              onButtonClick={handleAddSpeaker}
            />
          </div>
        ) : (
          <DraggableToastList
            ceremonyId={ceremony?.id || 0}
          />
        )}
      </div>
      <PaywallModal
        isOpen={isPaywallModalOpen}
        onClose={() => setIsPaywallModalOpen(false)}
        type={'guests'}
        referrer={referrer}
        ceremonyId={ceremony?.id || 0}
        isCeremonyBuilder={true}
        displayGif={upsellGif}
      />
    </React.Fragment>
  );
};

export default ToastBuilderDashboard;
